import { ConstantineBobLargeStare } from '@krakentech/coral-media';
import { IconDismiss } from '@krakentech/icons';
import { getCookie, setCookie } from 'cookies-next';
import { FC, useEffect, useState } from 'react';

import { copy } from '@/copy';
import { readLocalStorageOnce } from '@/utils/readLocalStorageOnce';

type BatteryManager = {
	charging: boolean;
	level: number;
};

declare const navigator: Navigator & {
	getBattery?: () => Promise<BatteryManager>;
};

const IS_CONSTANTINE_ENERGY_ALERT_DISMISSED =
	'is_constantine_energy_alert_dismissed';

export const ConstantineEnergyAlert: FC = () => {
	const [isHidden, setIsHidden] = useState(true);

	const getShouldDisplayBatteryWarning = async () => {
		const isConstantineDismissed = getCookie(
			IS_CONSTANTINE_ENERGY_ALERT_DISMISSED
		);

		const hasDismissedPrivacyCookie = readLocalStorageOnce<string>(
			'dismissedPrivacyCookieMessage'
		);

		if (
			navigator?.getBattery &&
			!isConstantineDismissed &&
			hasDismissedPrivacyCookie
		) {
			const battery = await navigator.getBattery();
			const shouldDisplayWarning = battery.level >= 0.8 && battery.charging;
			setIsHidden(!shouldDisplayWarning);
		}
	};

	const onClickClose = () => {
		// set cookie that expires after 1 week
		setCookie(IS_CONSTANTINE_ENERGY_ALERT_DISMISSED, 1, { maxAge: 604800 });
		setIsHidden(true);
	};

	useEffect(() => {
		getShouldDisplayBatteryWarning();
	}, []);

	return isHidden ? null : (
		<>
			<div className="invisible fixed left-0 bottom-10 z-10 md:visible">
				<div className="w-[150px]">
					<ConstantineBobLargeStare
						attributes={{
							'data-testid': 'constantineEnergyAlert',
						}}
						loop
						size={150}
					/>
				</div>
				{/* eslint-disable tailwindcss/enforces-negative-arbitrary-values */}
				{/* eslint-disable prettier/prettier */}
				<div className="absolute left-full bottom-0 block h-36 w-72 rounded-lg border-2 border-ink bg-white py-6 pl-4 pr-6 text-black before:absolute before:top-14 before:-left-[0.7rem] before:h-[20px] before:w-[20px] before:rotate-45 before:border-t-[0px] before:border-r-[0px] before:border-l-[2px] before:border-b-[2px] before:border-solid before:border-t-transparent before:border-r-transparent before:border-l-ink before:border-b-ink before:bg-white before:content-['']">
					<span className="h-full text-sm">
						ハロー！👋
						お使いのデバイスは80%以上充電されているようです。少しの間コンセントを抜いて省エネしてみませんか？
					</span>
					<button
						onClick={onClickClose}
						className="absolute top-1 right-1 z-10 cursor-pointer px-2 pt-2 text-right"
						aria-label={copy.close}
					>
						<IconDismiss size={12} color="black" />
					</button>
				</div>
			</div>
		</>
	);
};
