import { faker } from '@faker-js/faker/locale/ja';
import dateFnsFormat from 'date-fns/format';

import { random } from '@/utils/random';

export const aNumber = (): number =>
	faker.datatype.float({ min: 0, max: 1, precision: 0.000001 });

export const anAccountNumber = (): string =>
	`A-${faker.random.numeric(7, { allowLeadingZeros: false })}`;

export const aMonetaryAmount = (): number =>
	Number(faker.random.numeric(5, { allowLeadingZeros: false }));

export const aReferralCode = (): string =>
	`${faker.color.human()}-${faker.animal.type()}-${faker.random.numeric(4)}`;

export const aReferralUrl = (): string =>
	`http://localhost:3000/friend/witty-llama-${aReferralCode()}`;

export const aDateSoon = (): string =>
	dateFnsFormat(faker.date.soon(20), 'yyyy-MM-dd');

export const aDateSoonInMilliSeconds = (): number =>
	faker.date.soon(20).getTime();

export const aDateRecent = (): string =>
	dateFnsFormat(faker.date.recent(20), 'yyyy-MM-dd');

export const aDatetimeRecent = (): string =>
	faker.date.recent(20).toISOString();

export const aDateNextSixMonthFromToday = (): string =>
	dateFnsFormat(
		faker.date.between(
			new Date(),
			new Date(new Date().setMonth(new Date().getMonth() + 6))
		),
		'yyyy/MM/dd'
	);

export const aFirstName = (): string => faker.name.firstName();

export const aLastName = (): string => faker.name.lastName();

export const aUserName = (): string =>
	`${faker.name.lastName()} ${faker.name.firstName()}`;

export const aUuid = (): string => faker.datatype.uuid();

export const aUrl = (): string => faker.internet.url();

export const aSlug = (): string => faker.lorem.slug();

export const aText = (): string => faker.lorem.paragraph();

export const anEmailAddress = (): string => {
	faker.setLocale('en');
	const email = faker.internet.email();
	faker.setLocale('ja');
	return email;
};

export const aGridOperatorCode = (): string =>
	`${random(1, 11)}`.padStart(2, '0');

export const aMobileNumber = (): string =>
	faker.helpers.arrayElement([
		faker.phone.number('+8170########'),
		faker.phone.number('+8180########'),
		faker.phone.number('+8190########'),
	]);

export const aPriceAmount = (): number =>
	Number(faker.commerce.price(500, 100000));

export const anImageUrl = (): string => '/images/EarthBuiltToLast.png';
