import { faker } from '@faker-js/faker/locale/ja';
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer';

import { StoryBlokFuelMixContextProp } from '@/components/contexts/storyblokFuelMix';
import {
	Asset,
	BannerItemFragment,
	BlogpostItemFragment,
	FaqContentFragment,
} from '@/services/graphql-storyblok';
import { anImageUrl } from '@/test/factories/common';

export const storyBlokRichTextFixture: StoryblokRichtext = {
	type: 'doc',
	content: [],
};

export const fuelMixStoriesFixture: StoryBlokFuelMixContextProp = {
	fuelMixStandard: storyBlokRichTextFixture,
	fuelMixGreen: storyBlokRichTextFixture,
	fuelMixSimple: storyBlokRichTextFixture,
};

export const commonStoryblokBannerContent: NonNullable<
	BannerItemFragment['content']
> = {
	_editable: null,
	_uid: null,
	component: null,
	banner_color: 'primary',
	banner_content: 'REWARD-2022-04-10000',
	display_end_date: null,
	display_start_date: null,
	restrict_to_d2c: false,
	should_display_banner: true,
	where_to_display: ['landing'],
};

export const storyblokPrimaryOrSecondaryBannerContentFixture: BannerItemFragment =
	{
		id: faker.datatype.number(),
		content: {
			...commonStoryblokBannerContent,
			banner_content: 'primary-banner',
		},
		slug: 'primary-banner',
	};

export const storyblokD2CBannerContentFixture: BannerItemFragment = {
	id: faker.datatype.number(),
	content: {
		...commonStoryblokBannerContent,
		banner_content: 'REWARD-2022-04-10000',
	},
	slug: 'REWARD-2022-04-10000',
};

export const storyblokEmailQuoteRewardBannerContentFixture: BannerItemFragment =
	{
		id: faker.datatype.number(),
		content: {
			...commonStoryblokBannerContent,
			banner_content: 'promo-quote banner content',
		},
		slug: 'PROMO-QUOTE-FOLLOW-UP-2022-06-5000',
	};

export const storyblokAffiliateBannerContentFixture: BannerItemFragment = {
	id: faker.datatype.number(),
	content: {
		...commonStoryblokBannerContent,
		banner_content: 'ten-allied banner content',
	},
	slug: 'PARTNER-TENALLIED-2022-03-15000',
};

export const storyblokNoRewardAffiliateBannerContentFixture: BannerItemFragment =
	{
		id: faker.datatype.number(),
		content: {
			...commonStoryblokBannerContent,
			banner_content: 'tokyogas-move-out banner content',
		},
		slug: 'tokyogas-move-out',
	};

export const storyblokBannerResultsFixture = [
	storyblokD2CBannerContentFixture,
	storyblokEmailQuoteRewardBannerContentFixture,
	storyblokAffiliateBannerContentFixture,
	storyblokNoRewardAffiliateBannerContentFixture,
];

export const storyblokBlogAsset = (): Asset => ({
	alt: faker.lorem.words(),
	filename: anImageUrl(),
	copyright: anImageUrl(),
	focus: faker.lorem.words(),
	id: faker.datatype.number(),
	name: faker.lorem.words(),
	title: faker.lorem.words(),
});

export const storyblokBlogFixture = (): BlogpostItemFragment => ({
	id: faker.datatype.number(),
	full_slug: faker.lorem.slug(),
	name: faker.lorem.words(),
	content: {
		title: faker.lorem.words(),
		excerpt: faker.lorem.sentence(),
		banner_image_desktop: storyblokBlogAsset(),
		banner_image_mobile: storyblokBlogAsset(),
	},
});

export const storyblokFaqFixture = (): FaqContentFragment => ({
	answer: faker.lorem.sentence(),
	question: faker.lorem.words(),
	tag: faker.lorem.word(),
});

const storyblokFaqFixtureTagPlans = (): FaqContentFragment => ({
	...storyblokFaqFixture(),
	tag: 'plans',
});

const storyblokFaqFixtureTagOther = (): FaqContentFragment => ({
	...storyblokFaqFixture(),
	tag: 'other',
});

const storyblokFaqFixtureTagPayments = (): FaqContentFragment => ({
	...storyblokFaqFixture(),
	tag: 'payments',
});

const storyblokFaqFixtureTagMoving = (): FaqContentFragment => ({
	...storyblokFaqFixture(),
	tag: 'moving',
});

const storyblokFaqFixtureTagApplications = (): FaqContentFragment => ({
	...storyblokFaqFixture(),
	tag: 'applications',
});

const storyblokFaqFixtureTagMyPage = (): FaqContentFragment => ({
	...storyblokFaqFixture(),
	tag: 'my_page',
});

export const storyblokFaqsFixture = (): FaqContentFragment[] => [
	storyblokFaqFixture(),
	storyblokFaqFixtureTagOther(),
	storyblokFaqFixtureTagPayments(),
	storyblokFaqFixtureTagPlans(),
	storyblokFaqFixtureTagMoving(),
	storyblokFaqFixtureTagApplications(),
	storyblokFaqFixtureTagMyPage(),
];
