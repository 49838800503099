import { useRouter } from 'next/router';

import { UsageTier } from '@/constants/industry/industry';
import { PAGES } from '@/constants/pages';
import { JoinFormValues, joinFormInitialValues } from '@/data/join';
import { useSessionStorage } from '@/hooks/useSessionStorage';
import { QuotedElectricityProduct } from '@/services/typed-graphql-sdk';
import { QuoteWithNonNullableQuotedSupplyPoints } from '@/types/join';

/**
 * Gets and pre-sets quote and selected product details for the your-details form.
 * @returns Function to start the OBJ at the your-details step.
 */
export function useStartOBJWithHomePageQuote(): (
	product: QuotedElectricityProduct,
	quote: QuoteWithNonNullableQuotedSupplyPoints,
	usageAmount: number,
	usageTier: UsageTier | ''
) => void {
	const router = useRouter();
	const [, setFormSessionState] = useSessionStorage<JoinFormValues>(
		'shortcut-octopus-form-values',
		joinFormInitialValues
	);
	const startOBJWithHomePageQuote = (
		product: QuotedElectricityProduct,
		quote: QuoteWithNonNullableQuotedSupplyPoints,
		usageAmount: number,
		usageTier: UsageTier | ''
	) => {
		setFormSessionState({
			...joinFormInitialValues,
			postalArea: {
				area: '',
				city: '',
				postcode: '',
				prefecture: '',
			},
			usageAmount: String(usageAmount),
			usageTier,
			quotedElectricityProduct: product,
			quote,
			productCode: product.product.code,
		});

		router.push({
			pathname: PAGES.shortcutYourDetails,
			query: {
				...Object.fromEntries(
					new URLSearchParams(window.location.search).entries()
				),
			},
		});
	};

	return startOBJWithHomePageQuote;
}
