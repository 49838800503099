import { CoralBarChart } from '@krakentech/coral-charts';
import { FC } from 'react';

import {
	getEstimatedPriceAfterMetiDiscount,
	getMetiDiscountPrice,
} from '@/constants/marketing';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { QuotedProductEstimatedCosts } from '@/services/typed-graphql-sdk';
import { currencyFormatter } from '@/utils/formatters/currencyFormatter';

const TariffBarChart: FC<{
	estimatedCosts?: QuotedProductEstimatedCosts;
}> = ({ estimatedCosts }) => {
	const { breakpoint } = useBreakpoint();

	const getMetiDiscountMonthlyBreakdown = (
		estimatedCosts: QuotedProductEstimatedCosts
	) =>
		estimatedCosts?.monthlyBreakdown.map((cost, month) => {
			const metiDiscountPrice = getMetiDiscountPrice(`${month + 1}`);
			return {
				x: `${month + 1}月`,
				y: metiDiscountPrice
					? getEstimatedPriceAfterMetiDiscount(
							Number(cost),
							Number(estimatedCosts.quotedMonth.usageAmount),
							metiDiscountPrice
						)
					: cost,
			};
		});

	return estimatedCosts ? (
		breakpoint === 'sm' ? (
			<CoralBarChart
				data={getMetiDiscountMonthlyBreakdown(estimatedCosts)}
				modifiers={{
					height: 520,
					bar: {
						horizontal: true,
						barWidth: 10,
						barRatio: 1,
					},
					chart: {
						padding: { top: 40, bottom: 80, left: 42, right: 0 },
						domainPadding: { y: [0, 10], x: [16, 10] },
					},
					xAxis: {
						tickFormat: (d) => `${currencyFormatter.format(d)}円`,
					},
					tooltip: {
						showTooltips: true,
						labelFormat: (d) => `${currencyFormatter.format(d.datum.y)}円`,
					},
				}}
			/>
		) : (
			<CoralBarChart
				data={getMetiDiscountMonthlyBreakdown(estimatedCosts)}
				modifiers={{
					height: 520,
					chart: {
						padding: { top: 40, bottom: 80, left: 80, right: 40 },
					},
					yAxis: {
						tickFormat: (d) => `${currencyFormatter.format(d)}円`,
					},
					tooltip: {
						showTooltips: true,
						labelFormat: (d) => `${currencyFormatter.format(d.datum.y)}円`,
					},
				}}
			/>
		)
	) : null;
};
export { TariffBarChart };
