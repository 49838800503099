import { Grid, Review } from '@krakentech/coral';
import { FC } from 'react';

export const GoogleReviews: FC = () => {
	return (
		<Grid
			templateColumns="repeat(1, 1fr)"
			gap="xxl"
			sm={{ templateColumns: 'repeat(1,1fr)', gap: 'sm' }}
			md={{ templateColumns: 'repeat(3,1fr)', gap: 'lg' }}
		>
			<Grid.Item>
				<Review>
					<Review.Quote>
						あとで何かどんでん返しがくるのではないかと心配になるくらいよい電気会社です！安いし連絡はマメだし節電特典などもあって楽しい！
					</Review.Quote>
					<Review.Author stars={5}>京都府 きよみ</Review.Author>
				</Review>
			</Grid.Item>
			<Grid.Item>
				<Review>
					<Review.Quote>
						現行の電力会社からの変更手続きも分かりやすく、簡単で早かったです！
					</Review.Quote>
					<Review.Author stars={5}>東京都 ツヨシ</Review.Author>
				</Review>
			</Grid.Item>
			<Grid.Item>
				<Review>
					<Review.Quote>
						楽しく節電が出来ました！愛とパワーのプレゼント頂きました！社員の方々、お体に気を付けてください。そして、これからもよろしくお願いします。
					</Review.Quote>
					<Review.Author stars={5}>壺だこ</Review.Author>
				</Review>
			</Grid.Item>
		</Grid>
	);
};
