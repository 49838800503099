import { Button, Container, Grid } from '@krakentech/coral';
import { FC } from 'react';

import { HomePageSectionHeading } from '@/components/pages/home/HomePageSectionHeading';
import {
	GA_EVENT_TYPE_EV_PRODUCT_CARD_HOMEPAGE,
	GA_EVENT_TYPE_PRODUCT_CARD_ALL_ELECTRIC,
	GA_EVENT_TYPE_SOLAR_PRODUCT_CARD_HOMEPAGE,
} from '@/constants/analytics';
import { PAGES } from '@/constants/pages';
import { sendTopProductCardClickAnalytics } from '@/utils/googleAnalytics';

const ProductsHomepageEstimate = [
	{
		title: 'ソーラーオクトパス',
		content:
			'太陽光パネルを設置済みのご家庭なら、夜間の料金を割安に設定した「ソーラーオクトパス」をどうぞ。',
		buttonText: 'もっと見る',
		href: PAGES.solarLandingPage,
		onClick: () =>
			sendTopProductCardClickAnalytics({
				type: GA_EVENT_TYPE_SOLAR_PRODUCT_CARD_HOMEPAGE,
			}),
	},
	{
		title: 'オール電化オクトパス',
		content:
			'オール電化だからこそ、地球にも家計にもやさしいグリーンな電気料金プランを',
		buttonText: 'もっと見る',
		href: PAGES.allDenki,
		onClick: () =>
			sendTopProductCardClickAnalytics({
				type: GA_EVENT_TYPE_PRODUCT_CARD_ALL_ELECTRIC,
			}),
	},
	{
		title: 'EVオクトパス',
		content:
			'EVオーナーの日常を考慮し、特に頻繁に充電を必要とする方々のために、この料金プランを最適化しました。',
		buttonText: 'もっと見る',
		href: PAGES.evLandingPage,
		onClick: () =>
			sendTopProductCardClickAnalytics({
				type: GA_EVENT_TYPE_EV_PRODUCT_CARD_HOMEPAGE,
			}),
	},
];

export const HomePageSectionProducts: FC = () => {
	return (
		<div>
			<HomePageSectionHeading
				headingText="お住まいや生活スタイルに合わせて選ぼう!"
				subheadingText="オクトパスのグリーンな電気料金プラン"
			/>
			<Grid
				templateColumns="repeat(1,1fr)"
				templateRows="repeat(1,1fr)"
				gap="lg"
				md={{
					templateColumns: 'repeat(3,1fr)',
					templateRows: 'repeat(1,1fr)',
				}}
			>
				{ProductsHomepageEstimate.map((data) => {
					return (
						<Container key={data.title}>
							<div className="flex h-full flex-col justify-between space-y-4">
								<div className="space-y-4">
									<p className="text-lg font-bold text-voltage">{data.title}</p>
									<p className="text-lg">{data.content}</p>
								</div>
								<Container maxWidth={200} marginY="md" paddingTop="xs">
									<Button
										fullWidth
										href={data.href}
										color="primary"
										onClick={data.onClick}
										target="_blank"
									>
										{data.buttonText}
									</Button>
								</Container>
							</div>
						</Container>
					);
				})}
			</Grid>
		</div>
	);
};
