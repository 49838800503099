import { FC } from 'react';

type AreaPinProps = {
	height?: string;
	width?: string;
};

const AreaPin: FC<AreaPinProps> = ({ height = 32, width = 32 }) => {
	return (
		<svg
			aria-labelledby="area-pin"
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title id="area-pin" lang="ja">
				マッピのピン
			</title>
			<circle cx="15.8854" cy="12.4518" r="4.69792" fill="#F0FFFF" />
			<path
				d="M17.3578 29.9945C20.2969 26.3164 27 17.4018 27 12.3945C27 6.32162 22.0729 1.39453 16 1.39453C9.92708 1.39453 5 6.32162 5 12.3945C5 17.4018 11.7031 26.3164 14.6422 29.9945C15.3469 30.8711 16.6531 30.8711 17.3578 29.9945ZM16 8.72787C16.9725 8.72787 17.9051 9.11417 18.5927 9.80181C19.2804 10.4894 19.6667 11.4221 19.6667 12.3945C19.6667 13.367 19.2804 14.2996 18.5927 14.9873C17.9051 15.6749 16.9725 16.0612 16 16.0612C15.0275 16.0612 14.0949 15.6749 13.4073 14.9873C12.7196 14.2996 12.3333 13.367 12.3333 12.3945C12.3333 11.4221 12.7196 10.4894 13.4073 9.80181C14.0949 9.11417 15.0275 8.72787 16 8.72787Z"
				fill="#5840FF"
			/>
		</svg>
	);
};

export { AreaPin };
