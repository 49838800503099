import * as Sentry from '@sentry/nextjs';

import { QuotedElectricityProduct } from '@/services/typed-graphql-sdk';

// Find cheapest product
export const getCheapestProduct = (
	quotedProducts: QuotedElectricityProduct[]
): QuotedElectricityProduct | undefined => {
	if (quotedProducts.length === 0) {
		Sentry.captureMessage('No quoted products found in getCheapestProduct');
		return undefined;
	}

	if (quotedProducts.length === 1) {
		return quotedProducts[0] as QuotedElectricityProduct;
	}

	const HasProductCost = quotedProducts.find((product) => {
		const productCost = product?.estimatedCosts.quotedMonth.cost;
		return (
			productCost !== '' || productCost !== undefined || productCost === null
		);
	})?.estimatedCosts.quotedMonth.cost;

	if (!HasProductCost) {
		Sentry.captureMessage('No product costs found in getCheapestProduct. ');
		return undefined;
	}

	const cheapestProduct = quotedProducts.reduce((a, b) => {
		return HasProductCost &&
			Number(a?.estimatedCosts.quotedMonth.cost) <
				Number(b?.estimatedCosts.quotedMonth.cost)
			? a
			: b;
	});

	return cheapestProduct as QuotedElectricityProduct;
};
