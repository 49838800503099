import { Accordion, Button, Card, Drawer } from '@krakentech/coral';
import { FC } from 'react';
import { render } from 'storyblok-rich-text-react-renderer';

import { useStoryBlokFuelMixContext } from '@/components/contexts/storyblokFuelMix';
import { ProductPriceMyPage } from '@/components/pages/account/MyPage/ProductPriceMyPage';
import { ConstantineLoader } from '@/components/shared/ConstantineLoader';
import { GridAreaMapCard } from '@/components/shared/ProductDetailsSideDrawer/GridAreaMapCard';
import { DEFAULT_AMPERAGE } from '@/constants/constants';
import {
	GridOperatorCodeByArea,
	SupportedArea,
} from '@/constants/industry/gridOperator';
import { StandingChargeUnitType } from '@/constants/industry/industry';
import { DetailElementGreenProductVariant } from '@/constants/products';
import { copy } from '@/copy';
import {
	QuotedElectricityProduct,
	QuotedProductEstimatedCosts,
	SteppedConsumptionRate,
} from '@/services/typed-graphql-sdk';
import { currencyFormatter } from '@/utils/formatters/currencyFormatter';
import { isNotEmpty } from '@/utils/isNotEmpty';

export const HomePageQuoteSideDrawer: FC<{
	estimatedCosts: QuotedProductEstimatedCosts;
	handleStartOBJ: VoidFunction;
	isGreen?: boolean;
	isRedirectingToObj: boolean;
	onClose: VoidFunction;
	selectedArea: SupportedArea;
	state: 'open' | 'closed';
	tariff: QuotedElectricityProduct;
}> = ({
	estimatedCosts,
	handleStartOBJ,
	isGreen,
	isRedirectingToObj,
	onClose,
	selectedArea,
	state,
	tariff,
}) => {
	const product = tariff.product as SupplyProduct;

	const { fuelMixGreen, fuelMixSimple } = useStoryBlokFuelMixContext();

	return (
		<Drawer open={state === 'open'} onClose={onClose}>
			<div className="space-y-4 bg-siphon p-8 text-ice">
				<header className="space-y-2 sm:space-y-8">
					<h3 className="text-center text-3xl font-bold sm:text-4xl">
						{currencyFormatter.format(Number(estimatedCosts.quotedMonth.cost))}
						<span className="text-xl tracking-tighter sm:text-2xl">円</span>
					</h3>
					<h2 className="mb-3 h-14 text-center text-2xl font-bold sm:text-3xl md:mb-6 2xl:h-8">
						{product?.displayName}
					</h2>
				</header>
				<GridAreaMapCard selectedArea={selectedArea} />
				<Card theme="mid">
					<h3 className="text-lg font-bold md:text-xl">
						{copy.howAreMyMonthlyCostsCalculated}
					</h3>
					<p className="mt-4 text-sm md:text-base">
						{isGreen
							? copy.monthlyCostCalculationExplanation
							: copy.monthlyCostCalculationExplanationSimple}
					</p>
				</Card>
				<Card theme="mid">
					<div className="space-y-4">
						<p className="text-lg font-bold sm:text-xl">
							{isGreen ? 'グリーンオクトパス' : 'シンプルオクトパス'}
							のメリット
						</p>
						<DetailElementGreenProductVariant />
						<p className="text-xs text-dustysky">
							{copy.ofgemRetailersExplainer}
						</p>
					</div>
				</Card>
				<Card theme="mid">
					<ProductPriceMyPage
						standingChargePerDay={estimatedCosts.standingChargePricePerDay}
						standingChargeUnitType={
							estimatedCosts.standingChargeUnitType as StandingChargeUnitType
						}
						standingCharges={product.standingChargesBad.filter(isNotEmpty)}
						consumptionCharges={
							product.consumptionCharges as SteppedConsumptionRate[]
						}
						gridOperatorCode={GridOperatorCodeByArea[selectedArea]}
						amperage={Number(DEFAULT_AMPERAGE.replace(/_/g, ''))}
						isPriceTentative
					/>
				</Card>
				{/* Green Octopus Power Supply Composition and Non-Fossil Certificate Usage: */}
				<Accordion title="電源構成と非化石証書使用状況" theme="mid">
					{render(product.params.is_simple ? fuelMixSimple : fuelMixGreen)}
				</Accordion>
				<Button
					onClick={handleStartOBJ}
					color="primary"
					fullWidth={true}
					loading={isRedirectingToObj}
					loadingLabel={<ConstantineLoader />}
				>
					{copy.applyNow}
				</Button>
			</div>
		</Drawer>
	);
};
