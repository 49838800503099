import { Button, Grid, Typography } from '@krakentech/coral';
import { IconChevronRight } from '@krakentech/icons';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

import { ConstantineWindSolar } from '@/components/svgs/ConstantineWindSolar';
import { IconTopSpeed } from '@/components/svgs/IconTopSpeed';
import { PAGES } from '@/constants/pages';

export const HomePageSectionHero: FC = () => {
	const router = useRouter();

	/**
	 * Starts the OBJ with no previous information
	 * (i.e. a quote or postcode)
	 */
	const startOBJWithCTAButton = () => {
		router.push({
			pathname: PAGES.join,
			query: {
				step: 'estimation',
				...Object.fromEntries(
					new URLSearchParams(window.location.search).entries()
				),
			},
		});
	};

	return (
		<Grid
			templateColumns="repeat(3, 1fr)"
			columnGap="sm"
			rowGap="sm"
			sm={{ columnGap: 'md' }}
		>
			<Grid.Item
				colStart={1}
				rowStart={1}
				rowEnd={1}
				colSpan={3}
				sm={{ colSpan: 2 }}
			>
				<div className="flex h-full">
					<Typography variant="homepageTitle" color="tertiary" textWrap="wrap">
						グリーンな電気を、もっと安く！
					</Typography>
				</div>
			</Grid.Item>
			<Grid.Item
				colStart={1}
				colSpan={3}
				rowStart={2}
				rowEnd={2}
				sm={{ colSpan: 2 }}
			>
				<h2 className="py-4 text-lg font-bold md:max-w-lg lg:w-3/4 lg:pt-1 lg:pb-8">
					オクトパスエナジーは、英国で契約件数No.1の電力会社です。オンラインでサクッと切り替えてみませんか？
					<br />
					<span className="inline-block pt-4">解約金・解約手数料も無料！</span>
				</h2>
				<div className="w-full pb-2 md:max-w-lg lg:w-3/4" id="maincontent">
					<Button
						startIcon={<IconTopSpeed />}
						endIcon={<IconChevronRight color="#100030" size={20} />}
						onClick={startOBJWithCTAButton}
						attributes={{ 'data-testid': 'start-obj-with-cta-button' }}
					>
						<span className="text-left font-bold">
							4分以内で
							<br className="inline sm:hidden" />
							カンタン申し込み
						</span>
					</Button>
				</div>
				<p className="mt-2 text-xs text-dustysky">
					※2023年12月時点 Ofgem'Retail Market Indicators'調べ。
					<br className="hidden sm:inline md:hidden" />
					Octopus EnergyとShell Energy Retailの合計契約件数
				</p>
			</Grid.Item>
			<Grid.Item
				colStart={3}
				colSpan={1}
				rowStart={1}
				rowSpan={1}
				sm={{ rowSpan: 2 }}
			>
				<div className="hidden sm:block">
					<ConstantineWindSolar />
				</div>
			</Grid.Item>
		</Grid>
	);
};
