import { ENECHANGE_PCW_ORGANIZATIONS } from '@/constants/marketing';

// TODO: Make keys more generic in case we need to update the product variant codes for future pricing tests
export const GREEN_PRODUCT_CODES = {
	DEC: 'JPN_GREEN_OCTOPUS_DEC_23',
	SEP: 'JPN_GREEN_OCTOPUS_SEP_24',
	FF_DEC_24: 'JPN_FF_OCTOPUS_DEC_24',
};

const GREEN_PRODUCT_VARIANTS = [GREEN_PRODUCT_CODES.SEP];

export const ALL_DENKA_PRODUCT_CODES = {
	OCT: 'JPN_ALLELECTRIC_OCTOPUS_OCT_24',
	NOV_DIRECT: 'JPN_ALLELECTRIC_OCTOPUS_NOV_24_DIRECT',
};

const ALL_DENKA_PRODUCT_VARIANTS = [ALL_DENKA_PRODUCT_CODES.NOV_DIRECT];

export const isAllDenkaProductVariant = (productCode: string): boolean =>
	ALL_DENKA_PRODUCT_VARIANTS.includes(productCode);

// Affiliate orgs that should show the SEP pricing variant
const VALID_ORGANISATION_NAMES_FOR_JPN_GREEN_OCTOPUS_SEP_24 = [
	...ENECHANGE_PCW_ORGANIZATIONS,
	// @note: Kakaku organizations soon to also begin quoting SEP variant
];

export const isGreenOctopusSep24Organisation = (
	affiliateOrgName: string
): boolean =>
	VALID_ORGANISATION_NAMES_FOR_JPN_GREEN_OCTOPUS_SEP_24.includes(
		affiliateOrgName
	);

export const isGreenProductVariant = (productCode: string): boolean =>
	GREEN_PRODUCT_VARIANTS.includes(productCode);
