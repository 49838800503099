import { Trans } from 'next-i18next';
import { FC } from 'react';

export const StandingChargesSimpleOctopus: FC = () => (
	<p className="pb-2">
		<Trans
			i18nKey="account:property-section.no-basic-charge-no-fuel-adjustment"
			components={{ br: <br /> }}
		/>
	</p>
);
