import { isAfter } from 'date-fns';

import {
	ENECHANGE_PCW_PRODUCT_UPDATES_START_DATE,
	FF_DEC_24_PRODUCT_START_DATE,
} from '@/constants/marketing';
import {
	GREEN_PRODUCT_CODES,
	isGreenOctopusSep24Organisation,
	isGreenProductVariant,
} from '@/domain/product/pricing-test/pricing-test';
import { useReadSessionStorage } from '@/hooks/useReadSessionStorage';
import {
	QuotedElectricityProduct,
	SalesChannelChoices,
	TariffSummary,
} from '@/services/typed-graphql-sdk';

export type UseGreenProductProps = {
	filterGreenProduct: (
		products: QuotedElectricityProduct[]
	) => QuotedElectricityProduct[];
	filterGreenTariff: (tariffs: TariffSummary[]) => TariffSummary[];
};

// Determine whether the product can be displayed or not.
// The focus is mainly on determining when to display the correct green product variant.
const canShowProduct = (
	productCode: string,
	isDirectChannel: boolean,
	isValidGreenOctopusSep24Organisation = false,
	isValidFFDec24 = false
): boolean => {
	// Show SEP product variant
	const showSEPVariant =
		productCode === GREEN_PRODUCT_CODES.SEP &&
		(isDirectChannel || isValidGreenOctopusSep24Organisation);

	// Show FF product variant
	const showFFDEC24Variant =
		productCode === GREEN_PRODUCT_CODES.FF_DEC_24 &&
		!isDirectChannel &&
		!isValidGreenOctopusSep24Organisation &&
		isValidFFDec24;

	// Show the default green product
	const showDefaultProduct =
		productCode === GREEN_PRODUCT_CODES.DEC &&
		!isDirectChannel &&
		!isValidGreenOctopusSep24Organisation &&
		!isValidFFDec24;

	// Show all other non green products as is
	const isGreenProduct =
		Object.values(GREEN_PRODUCT_CODES).includes(productCode);

	// Display green product variant for matching organisations
	return (
		showSEPVariant ||
		showFFDEC24Variant ||
		showDefaultProduct ||
		!isGreenProduct
	);
};

// Determine which green product to include in a list of products.
// The product is a generic type as it can possibly be a QuotedElectricityProduct or TariffSummary.
// Since the shape of the products are different, getCode function is used to locate the productCode.
export const filterGreenProduct = <T>(
	products: T[],
	getCode: (product: T) => string,
	affiliateSalesChannel: string | null,
	isValidGreenOctopusSep24Organisation: boolean
): T[] => {
	const isValidFFDec24 =
		isAfter(new Date(), FF_DEC_24_PRODUCT_START_DATE) &&
		products.some(
			(product) => getCode(product) === GREEN_PRODUCT_CODES.FF_DEC_24
		) &&
		(affiliateSalesChannel === SalesChannelChoices.Events ||
			affiliateSalesChannel === SalesChannelChoices.FieldSales);

	const isDirectChannel =
		Boolean(affiliateSalesChannel) === false ||
		affiliateSalesChannel === SalesChannelChoices.Direct;

	// Only filter the products if the green product variant(s) are present
	return products.some((product) => isGreenProductVariant(getCode(product)))
		? products.filter((product) =>
				canShowProduct(
					getCode(product),
					isDirectChannel,
					isValidGreenOctopusSep24Organisation,
					isValidFFDec24
				)
			)
		: products;
};

export const useGreenProduct = (): UseGreenProductProps => {
	const affiliateSalesChannel = useReadSessionStorage<string | null>(
		'affiliateSalesChannel'
	);

	const isValidGreenOctopusSep24Organisation =
		isAfter(new Date(), ENECHANGE_PCW_PRODUCT_UPDATES_START_DATE) &&
		isGreenOctopusSep24Organisation(
			useReadSessionStorage<string | null>(
				'affiliateOrganisationName'
			) as string
		);

	return {
		filterGreenProduct: (products) =>
			filterGreenProduct(
				products,
				({ product }) => product.code,
				affiliateSalesChannel,
				isValidGreenOctopusSep24Organisation
			),

		filterGreenTariff: (tariffs) =>
			filterGreenProduct(
				tariffs,
				({ code }) => code,
				affiliateSalesChannel,
				isValidGreenOctopusSep24Organisation
			),
	};
};
