import clsx from 'clsx';
import { FC, ReactNode } from 'react';

/**
 * References Coral Section Well
 * See: https://coral-pattern-library.vercel.app/?path=/story/helpers-container--sections
 */
const WellSection: FC<{
	backgroundColor: 'bg-siphon' | 'bg-hemocyanin';
	children: ReactNode;
	padding?: string;
}> = ({ backgroundColor, children, padding = 'px-8 py-24' }) => (
	<section
		className={clsx(
			padding,
			backgroundColor,
			'flex w-full items-center justify-center',
			{
				'shadow-xl': backgroundColor === 'bg-siphon',
			}
		)}
	>
		<div className="flex w-full flex-col sm:max-w-2xl md:max-w-3xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl">
			{children}
		</div>
	</section>
);

export { WellSection };
