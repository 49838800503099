import { FC } from 'react';

import { HomePageSectionSellingPoint } from '@/components/pages/home/HomePageSectionSellingPoint';
import { ConstantineTalkingOnHeadphones } from '@/components/svgs/ConstantineTalkingOnHeadphones';
import { PiggyBank } from '@/components/svgs/PiggyBank';
import { SmilingEarth } from '@/components/svgs/SmilingEarth';

const copies = {
	customerService: {
		description:
			'お客さまが必要な時に、声を聞き、サポートするために私たちがいます。' /** We are there to listen and support customers when they need us. */,
		title:
			'お客さまに寄り添ったカスタマーサービス' /** Customer service that is customer-focused. */,
	},
	greenElectricity: {
		description:
			'私たちのミッションはお財布にも地球にも優しい電気を、テクノロジーの力でよりスマートに皆さまへお届けすることです。' /** Our mission is to deliver smarter, wallet- and planet-friendly electricity to you through the power of technology. */,
		title: 'グリーンな電気を皆さまへ' /** Green electricity for everyone */,
	},
	longTermValue: {
		description:
			'新プランの提案、楽しく取り組める節電施策や、電気に関するお役立ち情報発信で、よりよい電気の在り方、そして価値を提案しています。' /** We propose a better way of being and the value of electricity by proposing new plans, energy-saving measures that are fun to work on and disseminating useful information on electricity. */,
		title:
			'「安い」だけでない長期的な価値を' /** Long-term value, not just 'cheap' */,
	},
};

export const HomePageSectionSellingPoints: FC = () => {
	return (
		<section className="flex w-full flex-col space-y-24 md:flex-row md:space-y-0 md:space-x-8 lg:space-x-24">
			<HomePageSectionSellingPoint
				title={copies.greenElectricity.title}
				description={copies.greenElectricity.description}
				image={<SmilingEarth />}
			/>
			<HomePageSectionSellingPoint
				title={copies.customerService.title}
				description={copies.customerService.description}
				image={<ConstantineTalkingOnHeadphones />}
			/>
			<HomePageSectionSellingPoint
				title={copies.longTermValue.title}
				description={copies.longTermValue.description}
				image={<PiggyBank />}
			/>
		</section>
	);
};
