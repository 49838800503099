import { FC } from 'react';

const SmilingEarth: FC = () => {
	return (
		<svg
			aria-labelledby="smiling-earth"
			width="164"
			height="144"
			viewBox="0 0 164 144"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title id="smiling-earth" lang="ja">
				笑っている地球のイラスト {/* Smiling Earth */}
			</title>

			<path
				d="M16.9655 76.7486C16.9655 76.7486 -0.250261 71.2156 1.78255 54.1279"
				stroke="#60F0F8"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.15517 51.3009C5.15517 52.5864 4.11312 53.6283 2.82757 53.6283C1.54205 53.6283 0.5 52.5864 0.5 51.3009C0.5 50.015 1.54209 48.9731 2.82757 48.9731C4.11309 48.9731 5.15517 50.015 5.15517 51.3009Z"
				fill="#60F0F8"
				stroke="#60F0F8"
			/>
			<path
				d="M6.66122 46.5736C6.5533 47.0126 6.3498 47.4213 6.06476 47.7671L6.66122 46.5736ZM6.66122 46.5736C6.81445 46.0794 7.14918 45.9665 7.30644 45.9862C7.38315 45.9957 7.44973 46.0332 7.49949 46.1157C7.55342 46.2051 7.61046 46.3898 7.55809 46.717L7.55809 46.717C7.51349 46.9958 7.33692 47.3222 7.03601 47.6772C6.74073 48.0255 6.35972 48.3616 5.97236 48.6591C5.58701 48.955 5.20749 49.2034 4.92337 49.3783C4.86139 49.4164 4.80413 49.4509 4.75256 49.4816L4.12201 48.9528L5.00045 48.5718C5.41224 48.3932 5.77634 48.1171 6.06474 47.7671L6.66122 46.5736ZM4.31264 48.0082L4.1117 48.9442L3.76014 48.6493C3.90576 47.3858 3.9675 46.1131 3.94484 44.8407L3.94463 44.8291L3.94389 44.8175C3.92616 44.542 3.99475 44.3738 4.06482 44.2798C4.13617 44.1841 4.22723 44.1387 4.31008 44.128C4.3934 44.1173 4.47285 44.1404 4.53502 44.1946C4.59293 44.2451 4.66942 44.3529 4.68703 44.5697C4.68045 45.727 4.55488 46.8797 4.31264 48.0082Z"
				fill="#60F0F8"
				stroke="#60F0F8"
			/>
			<path
				d="M15.7099 64.422C11.7058 103.898 41.5581 139.258 82.4095 143.394C99.04 145.129 115.778 141.311 130.001 132.538C147.968 121.408 160.775 102.886 163.478 80.853C163.538 80.3668 163.591 79.8808 163.638 79.3878C164.023 75.6262 164.101 71.8394 163.872 68.0652C162.879 52.3969 156.709 37.4993 146.326 25.7052C133.69 11.3053 116.028 2.25422 96.9385 0.395797C89.1368 -0.399269 81.2589 0.005055 73.5797 1.59467C47.665 6.99621 26.3816 25.6254 18.5666 50.5285C17.1432 55.0505 16.1862 59.7059 15.7099 64.422Z"
				fill="#60F0F8"
			/>
			<path
				d="M18.5813 50.4657C22.8725 45.4457 27.7117 45.0869 31.8695 48.0656C36.027 51.0443 30.9736 56.8619 34.543 59.2688C38.1124 61.6759 43.4864 57.4338 45.5585 60.725C47.6307 64.0163 45.2911 68.3844 40.9932 68.4644C36.6955 68.5441 31.729 66.6757 30.2386 71.1238C28.7478 75.5719 33.0125 80.9777 37.5911 84.6876C42.1697 88.3978 41.3743 93.1052 41.1068 99.0561C40.8394 105.007 43.9544 118.338 52.4699 120.711C60.9857 123.085 63.2584 115.991 64.3209 110.625C65.3838 105.259 69.1137 102.394 75.0959 102.646C81.0784 102.899 85.844 103.384 86.3921 99.681C86.9401 95.9775 84.0861 95.3459 81.2186 91.4696C78.351 87.5934 82.7559 84.7875 78.6988 80.7914C74.6415 76.7956 68.0708 78.8632 62.4294 73.4776C56.7879 68.0921 57.8776 57.4672 62.4898 56.0507C67.1016 54.6346 67.5896 58.2716 69.0668 60.6454C70.5441 63.0188 75.0491 62.64 77.3017 59.0828C79.5544 55.5255 79.2534 46.7291 84.6943 48.9497C90.1352 51.1705 98.3836 51.1904 99.2928 43.93C100.202 36.6693 92.809 35.0736 89.1395 35.7319C85.4697 36.3901 82.8295 33.5509 80.7574 30.2663C78.6853 26.9818 72.7363 26.5497 70.651 21.5564C68.5656 16.5631 74.6615 12.7332 76.3325 7.94597C77.3418 5.08694 75.4033 2.91277 73.6587 1.61621C47.7243 7.00848 26.4086 25.6055 18.5813 50.4657Z"
				fill="#18F0B8"
			/>
			<path
				d="M138.711 42.7948C142.002 48.5679 144.55 50.3514 143.246 54.8032C141.943 59.2554 143.306 62.6173 147.854 64.2686C151.77 65.6888 160.775 65.002 164 67.8619C163.022 52.3231 156.936 37.5489 146.696 25.8521C143.76 29.0426 136.006 38.0985 138.711 42.7948Z"
				fill="#18F0B8"
			/>
			<path
				d="M125.222 109.905C125.281 117.741 133.732 123.558 130.126 132.493C147.872 121.359 160.522 102.829 163.192 80.7881C160.489 81.2877 155.334 82.9801 152.928 88.7837C149.573 96.8324 146.718 101.677 140.852 101.077C134.985 100.477 125.162 102.029 125.222 109.905Z"
				fill="#18F0B8"
			/>
			<path
				d="M26.4654 88.0607C26.9543 88.1005 27.4369 88.1897 27.9046 88.3264C28.545 88.4547 29.1678 88.6485 29.7613 88.9041C31.5141 89.6834 33.0061 90.8858 34.0791 92.3833C37.3104 96.752 34.5756 99.6004 31.9202 99.3547C30.3156 99.202 28.9769 97.2168 28.1782 96.1213C26.9619 94.4547 25.4939 92.3499 25.0982 90.3381C25.0054 89.8653 25.0325 89.3789 25.1771 88.9173C25.2679 88.6305 25.4671 88.3832 25.7387 88.2202C25.9591 88.1037 26.2116 88.0486 26.4654 88.0607Z"
				fill="#18F0B8"
			/>
			<path
				d="M108.574 20.6483C108.308 21.6567 108.208 22.7028 108.278 23.7443C108.337 25.5522 108.672 27.7475 110.25 28.9216C113.07 31.0299 114.101 32.3977 114.713 34.8264C115.324 37.2548 117.888 41.9453 124.704 39.8837C131.52 37.8219 129.687 29.6621 125.716 27.4403C121.746 25.2189 122.43 18.2599 115.791 17.8128C113.97 17.6928 111.847 17.8128 110.21 18.6603C109.818 18.8598 109.47 19.1396 109.188 19.4817C108.907 19.8237 108.698 20.2208 108.574 20.6483Z"
				fill="#18F0B8"
			/>
			<path
				d="M82.6698 21.1078C82.6698 21.1078 84.3633 16.7985 90.4628 19.4353C96.5624 22.0718 96.6884 25.2704 95.6364 27.4284C94.5844 29.5862 88.981 29.7204 85.9181 27.3198C82.8549 24.9193 81.8691 22.6274 82.6698 21.1078Z"
				fill="#18F0B8"
			/>
			<path
				d="M92.0986 78.8162C92.0986 78.8162 90.8731 83.9881 87.5664 83.1118C84.2597 82.2352 84.8724 77.5566 84.8724 77.5566"
				stroke="#180048"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M69.4778 73.1492C69.4778 73.1492 70.6875 68.954 73.1383 69.5323C75.5891 70.1109 75.0914 74.3248 75.0914 74.3248"
				stroke="#180048"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M104.217 77.8887C104.217 77.8887 105.431 73.952 107.916 74.354C110.4 74.7561 109.811 79.1725 109.811 79.1725"
				stroke="#180048"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M110.665 85.7268C110.881 83.7378 108.712 81.9038 105.82 81.6301C102.928 81.3564 100.408 82.7468 100.192 84.7355C99.9763 86.7246 102.146 88.5586 105.037 88.8323C107.929 89.1059 110.449 87.7155 110.665 85.7268Z"
				fill="#FF48D8"
			/>
			<path
				d="M75.9272 79.7226C76.1266 77.5129 74.1243 75.4751 71.4548 75.171C68.7851 74.8669 66.4595 76.4118 66.2601 78.6215C66.0604 80.8316 68.0627 82.8694 70.7325 83.1734C73.4019 83.4775 75.7275 81.9326 75.9272 79.7226Z"
				fill="#FF48D8"
			/>
			<path
				d="M159.96 62.207C160.944 67.2939 161.03 72.4535 160.217 77.5568"
				stroke="white"
				strokeWidth="2.06965"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M157.537 52.5122C158.227 54.6133 158.767 56.7744 159.153 58.9753"
				stroke="white"
				strokeWidth="2.06965"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M127.241 130.877C127.464 130.877 127.645 130.515 127.645 130.069C127.645 129.623 127.464 129.261 127.241 129.261C127.018 129.261 126.837 129.623 126.837 130.069C126.837 130.515 127.018 130.877 127.241 130.877Z"
				fill="white"
			/>
			<path
				d="M122.798 133.301C123.244 133.301 123.606 132.939 123.606 132.493C123.606 132.047 123.244 131.685 122.798 131.685C122.352 131.685 121.99 132.047 121.99 132.493C121.99 132.939 122.352 133.301 122.798 133.301Z"
				fill="white"
			/>
			<path
				d="M30.6995 105.025C31.592 105.025 32.3153 104.482 32.3153 103.813C32.3153 103.143 31.592 102.601 30.6995 102.601C29.8074 102.601 29.0837 103.143 29.0837 103.813C29.0837 104.482 29.8074 105.025 30.6995 105.025Z"
				fill="white"
			/>
			<path
				d="M36.7587 104.217C37.4281 104.217 37.9705 103.674 37.9705 103.005C37.9705 102.336 37.4281 101.793 36.7587 101.793C36.0896 101.793 35.5469 102.336 35.5469 103.005C35.5469 103.674 36.0896 104.217 36.7587 104.217Z"
				fill="white"
			/>
			<path
				d="M33.9312 110.68C34.8231 110.68 35.5467 110.138 35.5467 109.468C35.5467 108.799 34.8231 108.256 33.9312 108.256C33.0388 108.256 32.3152 108.799 32.3152 109.468C32.3152 110.138 33.0388 110.68 33.9312 110.68Z"
				fill="white"
			/>
		</svg>
	);
};

export { SmilingEarth };
