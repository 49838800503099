import { FC } from 'react';

type IconTopSpeedProps = {
	height?: number;
	width?: number;
};

const IconTopSpeed: FC<IconTopSpeedProps> = ({ height = 46, width = 50 }) => {
	return (
		<svg
			aria-label="clock-icon"
			height={height}
			width={width}
			viewBox="0 0 50 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title id="top-speed-icon" lang="ja">
				時計のアイコン
			</title>
			<path
				d="M46.0361 9.67769L49.068 9.58358M42.2787 6.19467L42.2007 3.68503M44.3931 11.7036L46.3462 13.2073M39.5127 7.6682L37.8283 6.06412M44.8762 7.33222L46.654 5.81019"
				stroke="#F0FFFF"
				strokeWidth="1.30565"
				strokeLinecap="round"
			/>
			<path
				d="M15.4121 42.968L13.0495 44.6495M20.3065 42.53L22.96 43.7495M14.468 39.8621L11.7338 39.579M17.9824 44.3056L18.4677 46.6701"
				stroke="#F0FFFF"
				strokeWidth="1.30565"
				strokeLinecap="round"
			/>
			<path
				d="M28.3025 40.4908C37.3831 40.4908 44.7443 33.1296 44.7443 24.0491C44.7443 14.9685 37.3831 7.6073 28.3025 7.6073C19.222 7.6073 11.8608 14.9685 11.8608 24.0491C11.8608 33.1296 19.222 40.4908 28.3025 40.4908Z"
				fill="#5840FF"
			/>
			<path
				d="M28.3025 36.581C35.2237 36.581 40.8345 30.9703 40.8345 24.049C40.8345 17.1278 35.2237 11.5171 28.3025 11.5171C21.3813 11.5171 15.7706 17.1278 15.7706 24.049C15.7706 30.9703 21.3813 36.581 28.3025 36.581Z"
				fill="#BDF2FF"
			/>
			<path
				d="M28.3026 25.9454C27.255 25.9454 26.4062 25.0931 26.4062 24.0491C26.4062 23.005 27.2585 22.1528 28.3026 22.1528C29.3466 22.1528 30.1989 23.005 30.1989 24.0491C30.1989 25.0931 29.3466 25.9454 28.3026 25.9454ZM28.3026 23.2181C27.8445 23.2181 27.4716 23.591 27.4716 24.0491C27.4716 24.5072 27.8445 24.88 28.3026 24.88C28.7607 24.88 29.1335 24.5072 29.1335 24.0491C29.1335 23.591 28.7607 23.2181 28.3026 23.2181Z"
				fill="#5840FF"
			/>
			<path
				d="M1.27486 25.015C0.980114 25.015 0.742188 24.7771 0.742188 24.4859C0.742188 24.1911 0.976562 23.9532 1.27131 23.9496L9.14062 23.9106C9.43537 23.9106 9.6733 24.1485 9.6733 24.4397C9.6733 24.7344 9.43892 24.9724 9.14418 24.9759L1.27486 25.015Z"
				fill="#2D1A83"
			/>
			<path
				d="M2.96877 20.2245C2.67757 20.2245 2.4361 19.9866 2.4361 19.6954C2.4361 19.4006 2.67047 19.1627 2.96522 19.1592L9.45314 19.1237C9.74433 19.1237 9.98581 19.3616 9.98581 19.6528C9.98581 19.9475 9.75144 20.1854 9.45669 20.189L2.96877 20.2245Z"
				fill="#2D1A83"
			/>
			<path
				d="M6.23934 15.434C5.94814 15.434 5.71022 15.1996 5.70667 14.9049C5.70667 14.6101 5.94104 14.3722 6.23578 14.3687L11.1399 14.3332C11.4346 14.3154 11.6761 14.5675 11.6761 14.8623C11.6761 15.157 11.4417 15.3949 11.147 15.3985L6.24289 15.434H6.23934Z"
				fill="#2D1A83"
			/>
			<path
				d="M6.69032 34.596C6.39913 34.596 6.1612 34.3616 6.15765 34.0668C6.15765 33.7721 6.39203 33.5342 6.68677 33.5306L11.5909 33.4915C11.875 33.4773 12.1271 33.7259 12.1271 34.0207C12.1271 34.3154 11.8927 34.5533 11.598 34.5569L6.69388 34.596H6.69032Z"
				fill="#2D1A83"
			/>
			<path
				d="M2.96877 29.8054C2.67757 29.8054 2.4361 29.5675 2.4361 29.2763C2.4361 28.9816 2.67047 28.7437 2.96522 28.7401L9.45314 28.7046C9.74433 28.7046 9.98581 28.9425 9.98581 29.2337C9.98581 29.5285 9.75144 29.7664 9.45669 29.7699L2.96877 29.8054Z"
				fill="#2D1A83"
			/>
			<path
				d="M31.2465 3.2536H25.3587C24.6605 3.2536 24.0945 3.8196 24.0945 4.51781V5.45886C24.0945 6.15706 24.6605 6.72306 25.3587 6.72306H31.2465C31.9447 6.72306 32.5107 6.15706 32.5107 5.45886V4.51781C32.5107 3.8196 31.9447 3.2536 31.2465 3.2536Z"
				fill="#BDF2FF"
			/>
			<path
				d="M29.2259 22.9411C29.1477 22.9411 29.0732 22.9234 28.9986 22.8878C28.7322 22.76 28.6186 22.444 28.7464 22.1776L31.0192 17.3978C31.147 17.1315 31.4631 17.0214 31.7294 17.1457C31.9957 17.2735 32.1094 17.5896 31.9815 17.8559L29.7088 22.6357C29.6165 22.8275 29.4283 22.9411 29.2259 22.9411Z"
				fill="#5840FF"
			/>
		</svg>
	);
};

export { IconTopSpeed };
