import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import {
	GRID_AREA_NAME_BY_GRID_OPERATOR_CODE,
	GridOperatorCode,
} from '@/constants/industry/gridOperator';
import {
	getStandingChargeUnitTranslation,
	StandingChargeUnitType,
} from '@/constants/industry/industry';

export const StandingChargesDefault: FC<{
	amperage?: Maybe<number>;
	gridOperatorCode?: GridOperatorCode;
	kva?: Maybe<number>;
	standingChargePerDay?: string | null;
	standingChargeUnitType: StandingChargeUnitType;
}> = ({
	amperage,
	gridOperatorCode,
	kva,
	standingChargeUnitType,
	standingChargePerDay,
}) => {
	const { t } = useTranslation();

	return (
		<>
			<p>
				{t('common:basic-charge')}
				{gridOperatorCode && (
					<>
						&nbsp;(
						{`${GRID_AREA_NAME_BY_GRID_OPERATOR_CODE[gridOperatorCode]}${t(
							'electricity-supply-area'
						)}`}
						)
					</>
				)}
			</p>
			<div className="font-bold">
				{t('price-with-yen', { price: standingChargePerDay })}/
				{standingChargeUnitType !== 'YEN_DAY' && (kva || amperage || '')}
				{getStandingChargeUnitTranslation(standingChargeUnitType, t)}
			</div>
		</>
	);
};
