import { createContext, useContext } from 'react';
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer';

import { fuelMixStoriesFixture } from '@/test/fixtures/storyblokData';

export type StoryBlokFuelMixContextProp = {
	fuelMixGreen: StoryblokRichtext | null;
	fuelMixSimple: StoryblokRichtext | null;
	fuelMixStandard: StoryblokRichtext | null;
};

export const StoryBlokFuelMixContext =
	createContext<StoryBlokFuelMixContextProp>(fuelMixStoriesFixture);

export const useStoryBlokFuelMixContext = (): StoryBlokFuelMixContextProp =>
	useContext(StoryBlokFuelMixContext);
