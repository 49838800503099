import { FC } from 'react';

export const ConstantineWindSolar: FC = () => (
	<svg
		aria-hidden="true"
		width="100%"
		viewBox="0 0 352 400"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M178.89 398.982L92.1642 375.998C89.7653 375.363 90.1327 371.854 92.6104 371.729L203.667 366.09C206.035 365.969 208.413 366.158 210.733 366.651L347.129 395.699C349.685 396.244 349.286 400 346.672 399.995L184.79 399.753C182.801 399.753 180.816 399.491 178.895 398.982H178.89Z"
			fill="#211650"
		/>
		<path
			d="M140.049 341.459L221.447 378.825L221.489 379.455C218.26 381.915 219.006 381.658 215.027 382.618H207.761C202.79 383.588 196.612 382.618 192.018 381.679L47.4072 365.015L140.044 341.459H140.049Z"
			fill="#211650"
		/>
		<path
			d="M262.178 1.78429L252.618 32.3005L248.75 44.7315L227.505 112.557L227.537 112.766L224.859 112.73L224.413 112.798L224.245 111.702L223.773 108.649L220.649 89.9289C220.518 89.0687 220.618 88.3554 220.959 87.6368L240.356 44.2175L245.658 32.2324L259.6 1.03423C260.398 -0.848774 262.797 0.111088 262.178 1.78429Z"
			fill="#58BCAC"
		/>
		<path
			opacity="0.4"
			d="M225.311 111.323C225.311 111.323 241.904 56.9308 242.182 54.4288C242.256 51.9584 224.009 87.1638 223.353 88.6009C222.675 90.1011 225.305 111.323 225.305 111.323H225.311Z"
			fill="white"
		/>
		<path
			d="M225.809 378.967C225.856 380.661 224.843 382.219 223.258 382.817C217.562 384.989 211.693 384.538 207.761 383.777C207.583 383.751 207.41 383.709 207.242 383.677C207.074 383.646 206.911 383.604 206.748 383.573C206.554 383.525 206.354 383.483 206.176 383.436C204.391 382.985 203.158 381.375 203.189 379.534L203.756 359.665L203.782 358.6L204.039 349.432V349.421L207.73 219.426L207.756 217.301L207.84 210.818L208.113 190.378L208.596 154.103V122.773H208.627L216.05 123.429H216.097L219.63 123.649H219.635V125.889L219.657 129.875V130.227L219.84 163.366V163.764L223.961 312.532L224.318 325.587L225.793 378.962L225.809 378.967Z"
			fill="#58BCAC"
		/>
		<path
			opacity="0.4"
			d="M219.856 163.37V163.769C219.583 165.568 218.108 165.353 217.919 162.951C217.473 153.688 216.15 145.537 211.289 146.853C210.171 147.236 209.357 149.182 208.606 152.177V125.537L208.643 122.783L216.108 123.439L219.646 123.649V125.626L219.856 163.365V163.37Z"
			fill="#00735F"
		/>
		<path
			d="M291.143 186.911L279.364 174.449L271.348 166.077L229.883 122.275L227.998 120.324L229.373 117.654L235.274 119.653L251.384 125.401C252.103 125.742 252.823 126.057 253.405 126.875L277.091 158.483L284.482 168.501L295.464 183.087C297.49 186.14 293.532 189.424 291.138 186.916L291.143 186.911Z"
			fill="#58BCAC"
		/>
		<path
			d="M222.135 121.011C222.345 122.332 221.478 123.649 220.156 123.649H195.189C194.743 123.649 194.323 123.439 193.867 123.198C193.657 122.988 193.42 122.988 193.42 122.752C192.082 120.518 191.415 118.168 191.436 115.76C191.457 113.421 192.118 111.024 193.42 108.637C193.867 107.982 194.533 107.525 195.189 107.525H220.156C221.478 107.525 222.376 108.637 222.135 109.954V110.851C221.688 114.15 221.478 117.685 222.135 121.016V121.011Z"
			fill="#58BCAC"
		/>
		<path
			d="M213.793 109.125H224.092C227.662 109.125 230.56 112.02 230.56 115.587C230.56 119.154 227.662 122.049 224.092 122.049H213.793V109.125Z"
			fill="#58BCAC"
		/>
		<path
			opacity="0.4"
			d="M222.134 109.955V110.851H198.281C198.281 110.851 193.42 111.512 193.866 121.436V122.994C193.656 122.784 193.42 122.784 193.42 122.548C192.118 120.371 191.457 118.095 191.436 115.766C191.457 113.427 192.118 111.03 193.42 108.643C193.866 107.988 194.533 107.531 195.189 107.531H220.155C221.478 107.531 222.376 108.643 222.134 109.96V109.955Z"
			fill="#00735F"
		/>
		<path
			opacity="0.4"
			d="M204.051 349.432L203.788 358.605L204.045 349.437L204.051 349.432Z"
			fill="#00735F"
		/>
		<path
			opacity="0.4"
			d="M225.809 378.966C225.856 380.66 224.843 382.218 223.258 382.816C217.562 384.988 211.693 384.536 207.761 383.776C207.583 383.75 207.41 383.708 207.242 383.676C207.074 383.645 206.911 383.603 206.748 383.571C206.554 383.524 206.354 383.482 206.176 383.435C204.391 382.984 203.158 381.374 203.189 379.533L203.756 359.664L203.782 358.599L204.045 349.425L204.039 349.42L207.73 219.425L207.756 217.3L207.84 210.817L208.113 190.377L207.299 347.38C207.294 348.702 207.572 350.023 208.202 351.183C211.641 357.482 218.964 353.695 220.439 342.738C221.52 334.687 222.748 316.795 223.851 312.473C223.825 312.62 223.867 312.615 223.961 312.536L224.318 325.592L225.793 378.966H225.809Z"
			fill="#00735F"
		/>
		<path
			d="M225.353 120.79L224.975 121.304L223.704 123.292L222.812 124.766L221.542 126.754L217.274 133.877L213.242 140.753C212.901 141.472 212.108 142.054 211.489 142.358L209.809 143.077L174.239 160.218L161.331 166.47L134.307 179.583C132.212 180.575 130.401 177.522 132.459 176.289L156.26 161.215L168.486 153.52L215.709 123.649L222.854 119.201L224.702 120.916L225.353 120.817V120.785V120.79Z"
			fill="#58BCAC"
		/>
		<path
			opacity="0.4"
			d="M223.946 123.256L223.053 124.73L221.783 126.718L217.515 133.841C216.896 134.15 216.518 134.664 216.077 134.732C212.575 136.61 193.898 144.425 194.076 142.852C194.554 141.441 221.888 124.457 223.951 123.256H223.946Z"
			fill="white"
		/>
		<path
			d="M215.017 203.69C213.174 203.695 211.741 205.163 211.746 207.067C211.751 209.155 213.242 210.492 215.079 210.487C216.922 210.482 218.313 208.971 218.308 207.078C218.303 205.184 216.948 203.679 215.022 203.69H215.017ZM215.032 209.019C213.904 209.024 212.985 208.221 212.985 207.094C212.98 205.961 213.888 205.163 215.017 205.158C216.145 205.153 217.064 205.892 217.064 207.083C217.069 208.295 216.161 209.013 215.032 209.019Z"
			fill="#211650"
		/>
		<path
			d="M215.064 220.176C213.227 220.181 211.794 221.65 211.799 223.554C211.804 225.641 213.295 226.984 215.132 226.979C216.97 226.974 218.361 225.463 218.355 223.564C218.35 221.671 216.996 220.171 215.069 220.176H215.064ZM215.08 225.505C213.951 225.51 213.038 224.708 213.032 223.58C213.027 222.447 213.941 221.65 215.069 221.644C216.198 221.639 217.111 222.384 217.117 223.569C217.122 224.781 216.208 225.5 215.08 225.505Z"
			fill="#211650"
		/>
		<path
			d="M214.502 234.479C214.502 234.479 217.016 234.474 217.662 234.469C218.57 234.584 218.087 235.869 218.087 235.869L214.696 235.88C213.641 235.88 213.121 236.609 213.126 237.5C213.126 238.392 213.63 239.116 214.706 239.111C214.706 239.111 217.021 239.111 217.672 239.105C218.58 239.221 218.098 240.506 218.098 240.506H215.919C213.231 240.705 213.042 243.27 213.042 243.27C212.696 245.83 213.746 245.599 213.746 245.599C214.276 245.531 214.391 245.137 214.475 244.456C214.528 244.031 214.565 243.669 214.565 243.669C214.727 240.931 216.538 241.193 216.538 241.193C218.071 241.225 218.308 242.856 218.308 242.856C218.57 244.298 218.318 245.321 218.318 245.321C217.856 247.571 216.785 246.47 216.727 246.281C216.727 246.281 217.273 245.678 217.347 244.277C217.452 242.552 216.638 242.583 216.638 242.583C216.045 242.531 215.935 243.291 215.935 243.291L215.683 245.143C215.384 247.33 213.835 247.068 213.835 247.068C211.893 246.931 211.872 244.618 211.872 244.618C211.677 241.461 213.315 240.265 213.315 240.265C212.433 239.824 211.84 238.943 211.835 237.474C211.83 235.722 212.806 234.484 214.57 234.479H214.496H214.502Z"
			fill="#211650"
		/>
		<path
			d="M218.218 213.935C218.029 211.407 215.678 210.84 214.995 210.903C213.961 210.872 211.756 211.601 211.767 214.391C211.777 214.758 211.809 215.094 211.867 215.403H213.121C213.074 215.131 213.042 214.811 213.032 214.417C212.995 213.116 214.061 212.372 215.006 212.372C216.098 212.372 217 213.258 216.99 214.402V216.657L213.903 216.673C213.903 216.673 211.284 216.521 211.846 219.825H213.095C212.691 217.989 213.851 218.084 213.851 218.084L217.006 218.073V219.809H218.25V218.073H219.704V216.662H218.239L218.229 213.929L218.218 213.935Z"
			fill="#211650"
		/>
		<path
			d="M215.084 227.392L209.504 227.408C209.504 227.408 209.021 228.693 209.929 228.803H212.769C212.15 229.291 211.872 229.994 211.83 230.707C211.751 232.721 213.2 234.085 215.147 234.059C216.985 234.043 218.418 232.648 218.371 230.712C218.313 228.52 216.859 227.46 215.084 227.387V227.392ZM215.095 232.6C213.966 232.606 213.053 231.814 213.048 230.702C213.042 229.579 213.956 228.787 215.084 228.782C216.213 228.777 217.126 229.511 217.132 230.681C217.132 231.882 216.224 232.595 215.095 232.6Z"
			fill="#211650"
		/>
		<path
			d="M215.179 248.908C213.289 248.913 211.971 250.183 211.977 251.987C211.982 252.978 212.349 253.828 213.042 254.431L213.074 254.458L213.331 254.195L213.294 254.169C212.659 253.624 212.323 252.868 212.318 251.987C212.318 251.174 212.607 250.487 213.147 249.999C213.656 249.532 214.381 249.281 215.179 249.281H215.294L215.31 254.699H215.357C216.187 254.667 216.948 254.374 217.488 253.865C218.066 253.33 218.37 252.617 218.365 251.798C218.365 250.12 217.016 248.908 215.179 248.919V248.908ZM217.315 253.529C216.89 253.949 216.292 254.211 215.651 254.279L215.635 249.307C217.09 249.501 218.024 250.471 218.029 251.793C218.029 252.47 217.782 253.073 217.315 253.529Z"
			fill="#211650"
		/>
		<path
			d="M217.599 260.431C218.103 259.949 218.391 259.256 218.386 258.522C218.386 257.788 218.092 257.095 217.583 256.618C217.095 256.162 216.459 255.952 215.588 255.957L212.118 255.968V256.335L215.588 256.324C216.36 256.324 216.916 256.508 217.352 256.906C217.772 257.3 218.04 257.919 218.04 258.527C218.04 259.13 217.782 259.755 217.362 260.148C216.932 260.552 216.37 260.741 215.604 260.741L212.134 260.751V261.118L215.604 261.108C216.475 261.108 217.105 260.893 217.593 260.431H217.599Z"
			fill="#211650"
		/>
		<path
			d="M218.434 271.977C218.434 271.243 218.14 270.55 217.631 270.073C217.142 269.617 216.507 269.407 215.636 269.412L212.166 269.423V269.79L215.636 269.779C216.408 269.779 216.964 269.963 217.4 270.362C217.825 270.755 218.087 271.374 218.087 271.982V272.03H218.429V271.982L218.434 271.977Z"
			fill="#211650"
		/>
		<path
			d="M215.215 262.352C213.326 262.357 212.013 263.626 212.019 265.43C212.024 266.422 212.391 267.272 213.084 267.875L213.116 267.901L213.373 267.639L213.336 267.612C212.701 267.067 212.36 266.312 212.36 265.43C212.355 264.617 212.648 263.925 213.189 263.437C213.698 262.976 214.423 262.719 215.221 262.719H215.331L215.347 268.137H215.399C216.223 268.105 216.979 267.812 217.53 267.303C218.108 266.768 218.412 266.055 218.407 265.236C218.402 263.558 217.058 262.346 215.215 262.357V262.352ZM217.357 266.973C216.927 267.392 216.334 267.654 215.688 267.723L215.672 262.75C217.126 262.944 218.061 263.915 218.061 265.236C218.066 265.913 217.819 266.516 217.352 266.973H217.357Z"
			fill="#211650"
		/>
		<path
			d="M218.334 284.407L213.347 282.073L218.319 279.419V279.025L212.927 281.868L208.891 279.985V280.394L218.34 284.816L218.334 284.407Z"
			fill="#211650"
		/>
		<path
			d="M217.5 273.266C216.907 272.694 216.103 272.38 215.237 272.385C214.371 272.385 213.573 272.705 212.975 273.277C212.376 273.859 212.046 274.661 212.051 275.537C212.051 276.681 212.618 277.599 213.61 278.092H212.298C211.557 278.097 210.728 278.076 210.198 277.777C209.494 277.383 209.111 276.639 209.106 275.674C209.101 274.394 209.778 273.507 210.36 273.14L210.408 273.114L210.182 272.815L210.145 272.841C209.82 273.067 208.759 273.932 208.765 275.674C208.77 276.775 209.195 277.609 210.004 278.097C210.586 278.438 211.5 278.469 212.313 278.459H215.216L215.253 278.453C217.137 278.448 218.445 277.237 218.439 275.511C218.439 274.64 218.103 273.838 217.494 273.261L217.5 273.266ZM215.253 278.086C213.568 278.092 212.397 277.043 212.392 275.537C212.387 273.985 213.636 272.762 215.237 272.757C216.838 272.752 218.093 273.964 218.098 275.516C218.103 277.027 216.933 278.086 215.253 278.092V278.086Z"
			fill="#211650"
		/>
		<path
			d="M151.477 61.8553C151.965 51.8738 152.705 41.9028 153.188 31.916C155.367 37.6542 157.666 43.5235 159.976 49.1358C163.724 47.8875 167.603 46.5133 171.351 45.2649C171.367 55.7814 171.514 66.1773 171.524 76.6938C168.59 70.4101 165.782 64.2628 162.978 57.8585C158.968 59.3534 155.22 60.6017 151.472 61.8553H151.477Z"
			fill="#F9C150"
		/>
		<g opacity="0.9">
			<path
				d="M280.791 392.684H302.004V365.65H280.791V392.684Z"
				fill="#00998E"
			/>
			<path
				d="M273.516 397.006C273.516 394.059 275.915 391.662 278.865 391.662H304.429C307.38 391.662 309.779 394.059 309.779 397.006C309.779 397.835 309.101 398.512 308.272 398.512H275.027C274.198 398.512 273.521 397.835 273.521 397.006H273.516Z"
				fill="#00998E"
			/>
		</g>
		<path
			d="M243.226 370.046L352 370.329L330.929 300.626L222.155 300.338L243.226 370.046Z"
			fill="#58BCAC"
		/>
		<path
			d="M247.862 364.71L344.074 364.889L325.029 305.529L230.775 305.246L247.862 364.71Z"
			fill="#211650"
		/>
		<path
			d="M236.277 336.733H341.365L339.176 331.797H234.088L236.277 336.733Z"
			fill="#58BCAC"
		/>
		<path
			d="M292.346 303.128L311.643 368.598L316.603 368.136L297.028 302.404L292.346 303.128Z"
			fill="#58BCAC"
		/>
		<path
			d="M258.125 303.223L277.285 368.981L282.22 368.881L263.248 303.422L258.125 303.223Z"
			fill="#58BCAC"
		/>
		<path
			d="M219.043 311.446L236.938 374.561C237.29 375.783 237.998 376.832 238.938 377.603L243.227 370.05L222.15 300.348L219.515 307.434C219.032 308.724 218.875 310.098 219.038 311.457L219.043 311.446Z"
			fill="#00998E"
		/>
		<path
			d="M238.938 377.598C239.835 378.332 240.938 378.81 242.129 378.93L345.254 378.998C347.464 378.998 349.433 377.603 350.173 375.521L351.994 370.328L243.226 370.045L238.938 377.598Z"
			fill="#00998E"
		/>
		<path
			d="M149.573 346.437C149.817 353.027 153.73 362.545 160.333 366.206C166.289 369.508 171.094 371.087 171.094 374.748C171.094 378.409 153.241 387.683 134.899 378.653C116.557 369.623 114.845 358.396 113.622 353.759C133.676 341.8 149.573 346.437 149.573 346.437Z"
			fill="#FF48D8"
		/>
		<path
			d="M171.347 334.811C173.916 322.712 191.86 305.42 194.099 281.648C197.035 250.483 181.255 195.119 104.19 195.119C27.1244 195.119 15.748 252.291 15.748 276.148C15.748 308.046 39.2346 324.545 38.1337 335.911C57.2166 350.944 129.511 368.543 171.347 334.811Z"
			fill="#FF48D8"
		/>
		<path
			d="M58.8066 346.437C58.5615 353.027 54.6402 362.545 48.0229 366.206C42.0548 369.508 37.2393 371.087 37.2393 374.748C37.2393 378.409 55.1304 387.683 73.5116 378.653C91.8929 369.623 93.6085 358.396 94.8339 353.759C74.737 341.8 58.8066 346.437 58.8066 346.437Z"
			fill="#FF48D8"
		/>
		<path
			d="M125.043 350.894C125.043 350.894 123.574 363.851 121.37 371.456C119.166 379.061 116.469 391.813 103.249 391.813C90.0283 391.813 87.0195 377.267 86.107 372.019C85.1275 366.386 83.6582 349.486 83.6582 349.486C83.6582 349.486 103.983 331.177 125.043 350.894Z"
			fill="#FF48D8"
		/>
		<path
			d="M171.406 334.715C169.48 348.379 183.418 349.084 182.562 356.896C181.706 364.707 152.591 364.254 145.002 348.731C137.413 333.208 171.406 334.715 171.406 334.715Z"
			fill="#FF48D8"
		/>
		<path
			d="M38.1836 334.394C38.42 348.19 24.5003 347.184 24.3921 355.042C24.2839 362.899 53.2349 366.013 62.67 351.536C72.1051 337.059 38.1836 334.394 38.1836 334.394Z"
			fill="#FF48D8"
		/>
		<path
			d="M69.9832 316.708C81.5546 316.708 90.9351 307.335 90.9351 295.773C90.9351 284.211 81.5546 274.838 69.9832 274.838C58.4117 274.838 49.0312 284.211 49.0312 295.773C49.0312 307.335 58.4117 316.708 69.9832 316.708Z"
			fill="white"
		/>
		<path
			d="M71.4004 313.824C79.5509 313.824 86.1582 307.222 86.1582 299.078C86.1582 290.934 79.5509 284.332 71.4004 284.332C63.2499 284.332 56.6426 290.934 56.6426 299.078C56.6426 307.222 63.2499 313.824 71.4004 313.824Z"
			fill="url(#paint0_linear_0_8618)"
		/>
		<path
			d="M62.8091 294.558C64.6459 294.558 66.1348 293.07 66.1348 291.235C66.1348 289.4 64.6459 287.912 62.8091 287.912C60.9724 287.912 59.4834 289.4 59.4834 291.235C59.4834 293.07 60.9724 294.558 62.8091 294.558Z"
			fill="white"
		/>
		<path
			d="M135.667 315.082C146.339 315.082 154.99 306.438 154.99 295.774C154.99 285.111 146.339 276.467 135.667 276.467C124.995 276.467 116.344 285.111 116.344 295.774C116.344 306.438 124.995 315.082 135.667 315.082Z"
			fill="white"
		/>
		<path
			d="M135.625 313.667C143.733 313.667 150.306 307.099 150.306 298.998C150.306 290.896 143.733 284.328 135.625 284.328C127.516 284.328 120.943 290.896 120.943 298.998C120.943 307.099 127.516 313.667 135.625 313.667Z"
			fill="url(#paint1_linear_0_8618)"
		/>
		<path
			d="M128.057 293.952C129.932 293.952 131.451 292.433 131.451 290.56C131.451 288.687 129.932 287.168 128.057 287.168C126.182 287.168 124.662 288.687 124.662 290.56C124.662 292.433 126.182 293.952 128.057 293.952Z"
			fill="white"
		/>
		<path
			d="M156.562 257.172C157.076 257.385 157.501 257.59 157.96 257.817C158.411 258.04 158.859 258.28 159.306 258.537C159.753 258.792 160.201 259.075 160.655 259.402C161.108 259.743 161.568 260.078 162.067 260.721C162.567 261.367 162.445 262.293 161.795 262.79C161.352 263.129 160.777 263.181 160.297 262.974L159.718 262.722C159.602 262.671 159.271 262.501 158.927 262.381C158.578 262.253 158.193 262.132 157.788 262.027C157.385 261.921 156.972 261.824 156.55 261.74C156.138 261.658 155.685 261.574 155.31 261.528C154.046 261.372 153.148 260.228 153.305 258.972C153.461 257.716 154.613 256.823 155.877 256.979C156.086 257.004 156.286 257.057 156.472 257.134L156.562 257.172Z"
			fill="#100030"
		/>
		<path
			d="M52.4235 263.808C52.0379 263.825 51.5714 263.882 51.1453 263.936C50.7096 263.993 50.2796 264.064 49.8579 264.145C49.4338 264.225 49.0266 264.324 48.6462 264.435C48.2708 264.541 47.904 264.69 47.7069 264.783L47.3523 264.95C46.7748 265.222 46.0883 264.969 45.8195 264.384C45.6411 263.996 45.6925 263.559 45.9153 263.23C46.3358 262.612 46.7647 262.244 47.1906 261.88C47.6181 261.525 48.0483 261.21 48.4803 260.922C48.9131 260.631 49.3493 260.357 49.7904 260.099C50.2406 259.839 50.6575 259.598 51.1646 259.35L51.296 259.285C52.4661 258.712 53.8742 259.207 54.4407 260.391C55.0073 261.575 54.518 262.999 53.3479 263.572C53.0532 263.717 52.7312 263.793 52.4235 263.808Z"
			fill="#100030"
		/>
		<path
			d="M94.7672 328.647C95.018 329.164 95.3237 329.552 95.7763 329.917C96.232 330.276 96.7898 330.59 97.4094 330.834C98.6524 331.316 100.107 331.546 101.559 331.479C102.999 331.43 104.508 331.081 105.838 330.517C106.5 330.238 107.127 329.902 107.603 329.528C108.098 329.153 108.391 328.812 108.611 328.465L109.052 327.77C109.525 327.023 110.515 326.801 111.262 327.274C111.692 327.546 111.949 327.996 111.999 328.465C112.074 329.201 111.992 329.984 111.743 330.629C111.683 330.792 111.631 330.973 111.556 331.12L111.324 331.565C111.17 331.876 110.981 332.113 110.799 332.385C110.032 333.394 109.122 334.153 108.156 334.761C106.214 335.954 104.044 336.611 101.771 336.706C99.5171 336.806 97.1871 336.326 95.1392 335.071C94.1232 334.441 93.1745 333.604 92.4679 332.509C91.7799 331.413 91.3518 329.995 91.5788 328.647C91.7236 327.787 92.5387 327.207 93.3997 327.352C93.9206 327.439 94.3389 327.772 94.5559 328.212L94.7672 328.647Z"
			fill="#100030"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_0_8618"
				x1="85.3582"
				y1="299.078"
				x2="56.2176"
				y2="299.078"
				gradientUnits="userSpaceOnUse"
			>
				<stop />
				<stop offset="0.4481" stopColor="#100030" />
				<stop offset="0.8399" stopColor="#180070" />
				<stop offset="1" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_0_8618"
				x1="121.024"
				y1="298.998"
				x2="149.55"
				y2="298.998"
				gradientUnits="userSpaceOnUse"
			>
				<stop />
				<stop offset="0.4481" stopColor="#100030" />
				<stop offset="0.8399" stopColor="#180070" />
				<stop offset="1" />
			</linearGradient>
		</defs>
	</svg>
);
