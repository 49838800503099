/**
 * Pads a number to two decimal places.
 * @param num
 * @returns string
 *
 * Note that `Intl.NumberFormat` will round numbers when it's more than the maximum fraction digits.
 */

export const padWithTrailingZeroes = (
	num: number,
	numberTrailingZeros: number
): string => {
	return new Intl.NumberFormat('ja-JP', {
		minimumFractionDigits: numberTrailingZeros,
		maximumFractionDigits: numberTrailingZeros,
	}).format(num);
};
