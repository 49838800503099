import { FC } from 'react';

const ConstantineTalkingOnHeadphones: FC = () => {
	return (
		<svg
			aria-labelledby="octopus-talking-on-headphones"
			width="261"
			height="141"
			viewBox="0 0 261 141"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title
				id="octopus-talking-on-headphones" /* Should be unique, and same as <svg>'s aria-labelledby*/
				lang="ja"
			>
				タコがヘッドフォンで会話をしているイラスト
				{/* Illustration of an octopus talking on headphones. */}
			</title>

			<path
				d="M165.277 106.793C166.521 110.789 170.377 115.932 174.896 117.037C178.958 118.057 182.068 118.227 182.689 120.437C183.27 122.648 174.232 131.319 161.878 128.896C149.524 126.473 146.664 119.928 145.171 117.292C155.08 106.665 165.277 106.793 165.277 106.793Z"
				fill="#FF48D3"
			/>
			<path
				d="M170.972 104.835C172.551 97.4055 183.601 86.798 185.014 72.2279C186.842 53.1177 177.079 19.1895 129.594 19.1895C82.11 19.1895 75.0889 54.232 75.0889 68.843C75.0889 88.4075 93.9917 98.8501 93.3268 105.826C105.042 115.03 145.174 125.514 170.972 104.835Z"
				fill="#FF48D3"
			/>
			<path
				d="M130.721 118.516C128.368 121.839 126.676 127.947 128.492 132.226C130.143 136.09 131.876 138.665 130.473 140.452C129.111 142.239 116.978 139.995 111.489 128.653C106 117.311 109.425 111.162 110.581 108.461C124.778 110.248 130.721 118.516 130.721 118.516Z"
				fill="#FF48D3"
			/>
			<path
				d="M152.005 113.553C152.005 113.553 149.136 122.997 149.051 127.906C149.009 132.773 157.152 138.432 149.178 140.595C145.845 141.51 142.343 140.803 139.389 139.181C136.985 137.891 134.833 136.06 133.145 133.897C131.289 131.484 130.361 128.655 129.264 125.868C128.42 123.746 127.618 121.665 126.816 119.543C126.816 119.502 136.014 105.273 152.005 113.553Z"
				fill="#FF48D3"
			/>
			<path
				d="M177.71 91.7754C173.204 103.082 186.032 103.063 186.859 107.733C187.686 112.403 170.481 117.072 163.326 109.357C156.171 101.642 177.71 91.7754 177.71 91.7754Z"
				fill="#FF48D3"
			/>
			<path
				d="M110.13 114.467C104.327 120.372 110.735 125.788 107.511 129.25C104.286 132.712 95.7021 125.911 93.848 114.263C93.566 112.512 93.526 109.01 93.445 106.485C93.284 100.865 110.13 114.467 110.13 114.467Z"
				fill="#FF48D3"
			/>
			<path
				d="M108.462 84.2662C115.373 84.2662 120.976 78.4765 120.976 71.334C120.976 64.1921 115.373 58.4023 108.462 58.4023C101.55 58.4023 95.9468 64.1921 95.9468 71.334C95.9468 78.4765 101.55 84.2662 108.462 84.2662Z"
				fill="white"
			/>
			<path
				d="M112.216 80.9288C117.054 80.9288 120.976 76.8198 120.976 71.751C120.976 66.6827 117.054 62.5737 112.216 62.5737C107.378 62.5737 103.456 66.6827 103.456 71.751C103.456 76.8198 107.378 80.9288 112.216 80.9288Z"
				fill="#100030"
			/>
			<path
				d="M118.891 73.4201C120.043 73.4201 120.976 72.4864 120.976 71.3341C120.976 70.1823 120.043 69.2485 118.891 69.2485C117.738 69.2485 116.805 70.1823 116.805 71.3341C116.805 72.4864 117.738 73.4201 118.891 73.4201Z"
				fill="white"
			/>
			<path
				d="M148.927 83.4317C155.608 83.4317 161.024 78.2021 161.024 71.751C161.024 65.3004 155.608 60.0708 148.927 60.0708C142.245 60.0708 136.829 65.3004 136.829 71.751C136.829 78.2021 142.245 83.4317 148.927 83.4317Z"
				fill="white"
			/>
			<path
				d="M151.429 80.0944C156.267 80.0944 160.189 76.1723 160.189 71.3341C160.189 66.4959 156.267 62.5737 151.429 62.5737C146.591 62.5737 142.668 66.4959 142.668 71.3341C142.668 76.1723 146.591 80.0944 151.429 80.0944Z"
				fill="#100030"
			/>
			<path
				d="M158.103 72.5857C159.255 72.5857 160.189 71.6519 160.189 70.4999C160.189 69.3479 159.255 68.4141 158.103 68.4141C156.951 68.4141 156.018 69.3479 156.018 70.4999C156.018 71.6519 156.951 72.5857 158.103 72.5857Z"
				fill="white"
			/>
			<path
				d="M161.52 46.0291C161.858 46.1621 162.111 46.2951 162.407 46.4721C162.703 46.6052 162.957 46.7822 163.253 46.9597C163.549 47.1367 163.802 47.3143 164.098 47.5358C164.395 47.7573 164.691 47.9788 164.986 48.4219C165.325 48.8649 165.24 49.485 164.817 49.8395C164.564 50.061 164.225 50.105 163.929 50.0165L163.422 49.8395C163.338 49.795 163.168 49.7065 162.957 49.618C162.745 49.5295 162.492 49.4404 162.238 49.3965C161.984 49.308 161.731 49.2634 161.477 49.2189C161.224 49.1749 160.927 49.1304 160.716 49.0864C159.87 48.9974 159.279 48.2003 159.363 47.3143C159.447 46.4282 160.209 45.8076 161.054 45.8961C161.224 45.8961 161.35 45.9406 161.477 46.0291H161.52Z"
				fill="#100030"
			/>
			<path
				d="M97.8171 50.2133C97.5572 50.2133 97.2543 50.2504 96.9949 50.2876C96.735 50.3243 96.4322 50.3614 96.1723 50.3981C95.9129 50.4352 95.653 50.4724 95.3931 50.5462C95.1337 50.5829 94.9173 50.6939 94.7874 50.731L94.4845 50.842C94.0946 50.9897 93.6188 50.8049 93.4889 50.4724C93.4024 50.2504 93.4454 50.0285 93.5754 49.8436C93.8353 49.5112 94.1381 49.2892 94.398 49.1044C94.6574 48.9196 94.9603 48.7348 95.2202 48.5871C95.4801 48.4394 95.7829 48.2913 96.0423 48.1436C96.3457 47.9955 96.6051 47.885 96.908 47.7369L96.9949 47.7002C97.7741 47.3672 98.7262 47.6259 99.1156 48.3284C99.5054 48.9939 99.202 49.8069 98.3799 50.1394C98.2934 50.1766 98.0335 50.2133 97.8171 50.2133Z"
				fill="#100030"
			/>
			<path
				d="M142.4 99.757C143.554 103.071 140.793 106.468 137.867 105.895C135.765 105.486 133.911 104.054 133.499 100.985C133.334 99.6747 135.889 101.025 138.032 100.575C140.175 100.125 141.947 98.4882 142.4 99.757Z"
				fill="#100030"
			/>
			<path
				d="M117.469 113.322L105.024 113.379C86.8771 113.462 72.0528 100.552 71.9784 84.5994L71.9668 82.1497C71.9993 81.9151 72.1139 81.7002 72.2894 81.5444C72.4651 81.3886 72.6899 81.3025 72.9232 81.3013C73.1563 81.3003 73.3819 81.3845 73.559 81.5387C73.7359 81.6929 73.8524 81.9066 73.8869 82.1408L73.8983 84.5906C73.9686 99.6126 87.9277 111.77 105.016 111.691L117.461 111.634C117.58 111.616 117.702 111.624 117.817 111.658C117.933 111.692 118.04 111.75 118.131 111.83C118.223 111.909 118.296 112.008 118.347 112.119C118.398 112.23 118.424 112.351 118.425 112.473C118.425 112.596 118.4 112.717 118.35 112.828C118.301 112.94 118.228 113.039 118.138 113.119C118.047 113.2 117.94 113.26 117.825 113.295C117.709 113.33 117.588 113.339 117.469 113.322Z"
				fill="#FFA26B"
			/>
			<path
				d="M66.436 81.2398L62.6099 81.3011C61.687 81.3162 60.9264 80.3923 60.9211 79.2478L60.9053 75.817C60.901 74.8896 61.5034 74.1333 62.2556 74.1299L66.1956 74.1113L66.436 81.2398Z"
				fill="#5840FF"
			/>
			<path
				d="M66.436 81.2399L62.599 81.3011C61.6734 81.3162 60.9106 80.3942 60.9053 79.2518V79.241C61.1012 79.6993 61.4526 80.0694 61.8945 80.2827C62.3363 80.496 62.8387 80.5382 63.3085 80.4012C64.2901 80.111 64.7454 78.952 64.5744 78.1287C64.3366 76.9947 64.7568 76.1323 65.2167 75.0311C65.4869 74.3863 65.7795 74.1863 65.9709 74.1311C65.975 74.1311 65.9832 74.1274 65.9871 74.1274C66.0549 74.1064 66.1273 74.1059 66.1951 74.1264L66.436 81.2399Z"
				fill="#6675F6"
			/>
			<path
				d="M78.1438 41.1523L75.9609 41.1642L75.9902 48.8953L78.1732 48.8837L78.1438 41.1523Z"
				fill="#FFA26B"
			/>
			<path
				d="M186.343 36.6357L184.363 36.6457L184.389 43.1599L186.37 43.1501L186.343 36.6357Z"
				fill="#FFA26B"
			/>
			<path
				d="M79.223 51.6916L79.1892 48.785C79.1577 48.436 78.9761 48.1124 78.6843 47.8843C78.3922 47.6564 78.0134 47.5423 77.6297 47.5674L74.4658 47.5803C73.408 47.5848 72.5568 48.2682 72.571 49.1021L72.6297 52.5416L79.223 51.6916Z"
				fill="#5840FF"
			/>
			<path
				d="M188.112 47.1268L188.095 43.7494C188.066 43.4168 187.899 43.1084 187.632 42.8911C187.364 42.674 187.017 42.5655 186.665 42.5891L183.765 42.6014C182.795 42.6058 182.015 43.257 182.028 44.0516L182.087 47.5636L188.112 47.1268Z"
				fill="#5840FF"
			/>
			<path
				d="M79.2232 51.6913C77.1067 63.023 76.2121 73.7731 79.976 85.5667C79.98 86.3711 78.9249 87.8993 77.9506 87.9035L70.0068 87.9377C67.2081 87.9498 64.9266 86.0919 64.9152 83.7913L64.7769 56.12C64.7642 53.5877 67.2335 51.5151 70.3139 51.4779C74.4223 51.4227 79.0236 51.5253 79.2232 51.6913Z"
				fill="#5840FF"
			/>
			<path
				d="M180.85 47.6765C183.454 60.2357 184.916 70.347 182.186 79.7484C181.839 80.6994 183.254 83.1278 184.523 82.9511L191.789 82.9175C194.35 82.9054 196.418 81.0581 196.407 78.7913L196.281 51.5372C196.269 49.0432 193.992 47.0238 191.174 47.0112C187.417 46.9941 181.032 47.5131 180.85 47.6765Z"
				fill="#5840FF"
			/>
			<path
				d="M128.881 109.508L114.58 109.571C113.031 109.578 111.781 110.807 111.788 112.317C111.795 113.827 113.056 115.045 114.605 115.038L128.906 114.975C130.455 114.969 131.705 113.739 131.698 112.229C131.691 110.719 130.43 109.501 128.881 109.508Z"
				fill="#60F0F8"
			/>
			<path
				d="M79.0791 52.542C77.3446 64.9853 76.8284 77.1869 79.976 85.5674V86.0693L79.4741 86.8221C78.908 87.6146 78.4826 87.9011 77.5304 87.9052L69.7665 87.9384C67.0312 87.9501 64.8015 86.1423 64.7903 83.9037L64.7769 81.2274C65.0604 81.214 65.3419 81.282 65.5842 81.4223C65.8262 81.5629 66.018 81.7691 66.1342 82.0142C68.75 86.4735 74.3059 84.9142 74.5587 81.179C74.8224 77.2344 71.0965 69.5961 73.0449 62.1656C74.9892 54.7351 79.0791 52.542 79.0791 52.542Z"
				fill="#6675F6"
			/>
			<path
				d="M180.85 47.6768C183.237 59.4525 184.8 68.6395 182.152 79.8795C181.929 81.3584 183.302 83.0736 184.543 82.9546L191.797 82.9209C194.352 82.909 196.418 81.0555 196.407 78.7844L196.395 76.0692C196.13 76.0581 195.867 76.1295 195.642 76.2741C195.417 76.4187 195.24 76.6297 195.134 76.8794C192.732 81.426 187.528 79.8924 187.256 76.1051C186.973 72.1059 190.382 64.3245 188.492 56.8033C186.606 49.2821 180.85 47.6768 180.85 47.6768Z"
				fill="#6675F6"
			/>
			<path
				d="M187.558 39.6477L186.317 39.6559L184.063 39.675L183.229 39.7822C183.228 39.7754 183.223 39.7417 183.212 39.6812L183.118 39.6808L183.212 39.671C183.033 38.6049 181.412 30.0211 174.667 21.9379C165.221 10.6185 148.672 6.57454 128.835 7.53111C108.94 8.47952 94.9671 13.4598 85.8903 25.3402C79.0226 34.3291 79.1209 42.95 79.1103 43.0383L77.9011 43.0656L74.7574 43.1394C74.7982 42.7427 73.7911 32.7842 81.2107 22.9903C88.1148 13.8852 101.371 1.39852 128.672 0.0954079C156.067 -1.20731 172.085 11.1118 179.336 19.866C187.101 29.2497 187.509 39.2436 187.558 39.6477Z"
				fill="#60F0F8"
			/>
			<path
				d="M187.558 39.6481L186.317 39.6561C186.29 38.9841 186.174 37.4902 185.786 34.5205C185.189 29.9921 182.786 25.3168 178.284 19.7567C173.786 14.1963 162.531 5.65782 146.342 2.9044C130.149 0.14759 118.628 2.67536 107.839 5.65683C97.0547 8.63829 91.8214 13.6182 85.2806 19.9939C78.7442 26.3729 76.8434 34.302 76.3497 38.3876C75.8515 42.4734 78.0296 43.0178 78.0296 43.0178L77.9011 43.0656L74.7574 43.1394C74.7982 42.7427 73.7911 32.7842 81.2107 22.9902C88.1146 13.8852 101.371 1.39853 128.672 0.0954084C156.067 -1.20731 172.085 11.1119 179.336 19.8661C187.101 29.2498 187.509 39.2438 187.558 39.6481Z"
				fill="#BDF2FF"
			/>
			<path
				d="M192.942 98.6095C196.189 97.8571 199.385 96.8968 202.511 95.7345C207.12 93.9699 207.876 94.8358 211.212 97.03C214.548 99.2242 230.757 107.388 239.024 106.246C247.291 105.105 252.698 100.715 254.111 91.966C255.524 83.2167 251.756 74.0935 238.266 70.225C224.776 66.3567 215.386 65.3391 208.806 67.8413C202.226 70.3434 201.511 78.1442 200.799 82.266C200.086 86.3877 199.74 88.2876 197.104 89.9632C194.469 91.6391 186.968 99.2273 192.942 98.6095Z"
				fill="#18F0B8"
			/>
			<path
				d="M56.0365 30.103C56.0365 30.103 51.8264 13.851 42.5757 13.385C33.3247 12.919 20.0716 17.1013 13.7622 19.6366C7.45276 22.1719 -3.9086 32.3567 1.35109 44.6434C6.61073 56.9301 19.6451 57.5694 26.5888 54.0264C33.5325 50.4834 46.1515 42.9751 48.4584 43.1916C50.7658 43.4086 63.3957 46.3555 66.1293 44.1886C68.8629 42.0212 61.7556 39.1825 59.3933 37.5142C57.0315 35.8459 56.0365 33.3538 56.0365 30.103Z"
				fill="#FFA26B"
			/>
		</svg>
	);
};

export { ConstantineTalkingOnHeadphones };
