import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import {
	ConsumptionChargesEVTimeOfUse,
	ConsumptionChargesSolarOctopusTimeOfUse,
} from '@/components/shared/ConsumptionCharges';
import { ConsumptionChargesAllElectric } from '@/components/shared/ConsumptionChargesAllElectric';
import { StandingChargesAllElectric } from '@/components/shared/StandingCharges/StandingChargesAllElectric';
import { StandingChargesDefault } from '@/components/shared/StandingCharges/StandingChargesDefault';
import { StandingChargesSimpleOctopus } from '@/components/shared/StandingCharges/StandingChargesSimpleOctopus';
import { StandingChargesTimeOfUseMyPage } from '@/components/shared/StandingCharges/StandingChargesTimeOfUseMyPage';
import { GridOperatorCode } from '@/constants/industry/gridOperator';
import { StandingChargeUnitType } from '@/constants/industry/industry';
import { ProductChargeType, ProductType } from '@/constants/products';
import {
	StandingChargeFragment,
	SteppedConsumptionRate,
} from '@/services/typed-graphql-sdk';

export const ProductPriceMyPage: FC<{
	amperage?: Maybe<number>;
	consumptionCharges: SteppedConsumptionRate[];
	gridOperatorCode?: GridOperatorCode;
	isPriceTentative?: boolean;
	kva?: Maybe<number>;
	productChargeType?: ProductChargeType;
	standingChargePerDay?: string | null;
	standingChargeUnitType: StandingChargeUnitType;
	standingCharges: StandingChargeFragment[];
}> = ({
	amperage,
	consumptionCharges,
	gridOperatorCode,
	kva,
	productChargeType,
	standingChargePerDay,
	standingCharges,
	standingChargeUnitType,
}) => {
	const { t } = useTranslation();
	return (
		<div className="space-y-4 text-ice">
			{/* Standing Charges Section */}
			{productChargeType === ProductType.AllElectric ? (
				<StandingChargesAllElectric
					standingChargePerDay={standingChargePerDay}
					standingCharges={standingCharges}
				/>
			) : productChargeType === ProductType.EV ||
			  productChargeType === ProductType.Solar ? (
				<StandingChargesTimeOfUseMyPage
					standingCharges={standingCharges}
					standingChargeUnitType={standingChargeUnitType}
				/>
			) : productChargeType === ProductType.Simple ? (
				<StandingChargesSimpleOctopus />
			) : (
				<StandingChargesDefault
					standingChargePerDay={standingChargePerDay}
					standingChargeUnitType={standingChargeUnitType}
					kva={kva}
					amperage={amperage}
					gridOperatorCode={gridOperatorCode}
				/>
			)}
			{/* Consumption Charges Section */}
			<div
				className={
					productChargeType === ProductType.Simple ? 'flex' : 'flex flex-col'
				}
			>
				<p className="mb-3 mr-1 text-base font-bold">
					{t('common:consumption-charge')}
					{productChargeType === ProductType.Simple ? '・' : ':'}
				</p>
				{productChargeType === ProductType.AllElectric && gridOperatorCode ? (
					<ConsumptionChargesAllElectric
						consumptionCharges={consumptionCharges}
						gridOperatorCode={gridOperatorCode}
					/>
				) : productChargeType === ProductType.EV ? (
					<ConsumptionChargesEVTimeOfUse
						consumptionCharges={consumptionCharges}
					/>
				) : productChargeType === ProductType.Solar ? (
					<ConsumptionChargesSolarOctopusTimeOfUse
						consumptionCharges={consumptionCharges}
					/>
				) : (
					<ConsumptionChargesMyPage consumptionCharges={consumptionCharges} />
				)}
			</div>
		</div>
	);
};

const ConsumptionChargesMyPage: FC<{
	consumptionCharges: SteppedConsumptionRate[];
}> = ({ consumptionCharges }) => (
	<div className="flex max-w-sm flex-wrap justify-between">
		{consumptionCharges.map((charge, index) => (
			<div
				className="flex flex-col"
				key={(charge.pricePerUnitIncTax + index).toString()}
			>
				<div className="whitespace-nowrap text-base font-bold">
					{charge.pricePerUnitIncTax}円/kWh
				</div>
				{charge.stepStart || charge.stepEnd ? (
					<div className="whitespace-nowrap text-xs text-pollution">
						{consumptionChargeStepLabel(charge)}
					</div>
				) : null}
			</div>
		))}
	</div>
);

const consumptionChargeStepLabel = (
	charge: Pick<SteppedConsumptionRate, 'stepEnd' | 'stepStart'>
): string => `${
	charge.stepStart && charge.stepStart > 0
		? charge.stepStart + 1
		: charge.stepStart
}
${charge.stepEnd ? ` ~ ${charge.stepEnd}kWh` : 'kWh ~'}`;
