import { FC, ReactNode } from 'react';

type HomePageSectionSellingPointProps = {
	description: string;
	image: ReactNode;
	title: string;
};

export const HomePageSectionSellingPoint: FC<
	HomePageSectionSellingPointProps
> = ({ title, description, image }) => (
	<figure className="flex flex-col items-center justify-center md:flex-1 md:items-start">
		{image}
		<figcaption className="mt-8 space-y-5 text-center md:mt-12 md:text-start">
			<p className="font-bold">{title}</p>
			<p>{description}</p>
		</figcaption>
	</figure>
);
