import { Button, Card } from '@krakentech/coral';
import { IconAccount } from '@krakentech/icons';
import { FC } from 'react';

import { House } from '@/components/svgs/House';
import {
	GA_EVENT_HOMEPAGE_PRICECHECK_HOUSEHOLD_1,
	GA_EVENT_HOMEPAGE_PRICECHECK_HOUSEHOLD_2_TO_3,
	GA_EVENT_HOMEPAGE_PRICECHECK_HOUSEHOLD_4_PLUS,
} from '@/constants/analytics';
import { UsageTier } from '@/constants/industry/industry';
import { sendHomepagePriceCheckHouseholdAnalytics } from '@/utils/googleAnalytics';

const UsageTierSelectSection: FC<{
	selectedUsageTier: UsageTier | '';
	setSelectedUsageTier: (usageTier: UsageTier) => void;
	usageHigh: number;
	usageLow: number;
	usageMedium: number;
}> = ({ setSelectedUsageTier, selectedUsageTier }) => {
	return (
		<div className="w-full xs:px-4 sm:px-0">
			<Card theme="dark" borderless fullHeight>
				<div className="mb-12 flex items-center space-x-4">
					<House />
					<p className="text-lg md:text-xl">何人でお住まいですか？</p>
				</div>
				<div className="grid grid-cols-1 gap-4 md:px-0">
					<Button
						id="LOW"
						variant={selectedUsageTier === 'LOW' ? 'contained' : 'outlined'}
						color="secondary"
						size="large"
						onClick={(e: React.MouseEvent<HTMLElement>) => {
							sendHomepagePriceCheckHouseholdAnalytics(
								GA_EVENT_HOMEPAGE_PRICECHECK_HOUSEHOLD_1
							);
							setSelectedUsageTier(e.currentTarget.id as UsageTier);
						}}
					>
						<div className="flex w-full justify-between text-sm md:text-base">
							<div className="flex min-w-max space-x-4">
								<IconAccount size={24} color="white" />
								<p>1人暮らし</p>
							</div>
						</div>
					</Button>
					<Button
						variant={selectedUsageTier === 'MEDIUM' ? 'contained' : 'outlined'}
						id="MEDIUM"
						color="secondary"
						size="large"
						onClick={(e: React.MouseEvent<HTMLElement>) => {
							sendHomepagePriceCheckHouseholdAnalytics(
								GA_EVENT_HOMEPAGE_PRICECHECK_HOUSEHOLD_2_TO_3
							);
							setSelectedUsageTier(e.currentTarget.id as UsageTier);
						}}
					>
						<div className="flex w-full justify-between text-sm md:text-base">
							<div className="flex min-w-max space-x-4">
								<IconAccount size={24} color="white" />
								<p>2~3人暮らし</p>
							</div>
						</div>
					</Button>
					<Button
						id="HIGH"
						variant={selectedUsageTier === 'HIGH' ? 'contained' : 'outlined'}
						color="secondary"
						size="large"
						onClick={(e: React.MouseEvent<HTMLElement>) => {
							sendHomepagePriceCheckHouseholdAnalytics(
								GA_EVENT_HOMEPAGE_PRICECHECK_HOUSEHOLD_4_PLUS
							);
							setSelectedUsageTier(e.currentTarget.id as UsageTier);
						}}
					>
						<div className="flex w-full justify-between text-sm md:text-base">
							<div className="flex min-w-max space-x-4">
								<IconAccount size={24} color="white" />
								<p>4人以上</p>
							</div>
						</div>
					</Button>
				</div>
			</Card>
		</div>
	);
};

export { UsageTierSelectSection };
