import { Alert, Button, Card, Skeleton } from '@krakentech/coral';
import { IconThermostat } from '@krakentech/icons';
import * as Sentry from '@sentry/nextjs';
import { useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { useMemo, useRef, useState } from 'react';

import { AreaSelectSection } from '@/components/pages/home/AreaSelectSection';
import { HomePageQuoteSideDrawer } from '@/components/pages/home/HomePageQuoteSideDrawer';
import { HomePageSectionHeading } from '@/components/pages/home/HomePageSectionHeading';
import { ShortcutForm } from '@/components/pages/home/Shortcut/ShortcutForm';
import { UsageTierSelectSection } from '@/components/pages/home/UsageTierSelectSection';
import { ConstantineLoader } from '@/components/shared/ConstantineLoader';
import { TariffBarChart } from '@/components/shared/TariffBarChart';
import { AreaPin } from '@/components/svgs/AreaPin';
import { House } from '@/components/svgs/House';
import {
	GA_EVENT_HOMEPAGE_PRICECHECK_QUOTE_APPLY,
	GA_EVENT_HOMEPAGE_PRICECHECK_QUOTE_DETAILS_APPLY,
} from '@/constants/analytics';
import { DEFAULT_AMPERAGE } from '@/constants/constants';
import {
	GridOperatorName,
	GridOperatorNameByArea,
	SupportedArea,
} from '@/constants/industry/gridOperator';
import { UsageTier } from '@/constants/industry/industry';
import {
	getEstimatedPriceAfterMetiDiscount,
	getMetiDiscountPrice,
} from '@/constants/marketing';
import { copy } from '@/copy';
import { useGreenProduct } from '@/hooks/useGreenProduct';
import { useProductParamsQuickQuote } from '@/hooks/useProductParamsQuickQuote';
import { useStartOBJWithHomePageQuote } from '@/hooks/useStartOBJWithHomePageQuote';
import {
	createAndGetQuote,
	DefaultUsageMonth,
} from '@/queriesAndMutations/quote';
import {
	PostalAreaFragment,
	QuotedElectricityProduct,
} from '@/services/typed-graphql-sdk';
import { QuoteWithNonNullableQuotedSupplyPoints } from '@/types/join';
import { currencyFormatter } from '@/utils/formatters/currencyFormatter';
import { getCheapestProduct } from '@/utils/getCheapestProduct';
import { getUsageAmount } from '@/utils/getUsageAmount';
import {
	sendHomepagePriceCheckQuoteApplyAnalytics,
	sendHomepagePriceCheckQuoteMoreDetailsAnalytics,
} from '@/utils/googleAnalytics';

const AreasData: Record<SupportedArea, PostalAreaFragment> = {
	// 01 - Hokkaido
	北海道: {
		postcode: '064-0941',
		prefecture: '北海道',
		city: '札幌市中央区',
		area: '旭ケ丘',
	},
	// 02 - Touhoku
	東北: {
		postcode: '981-0916',
		prefecture: '宮城県',
		city: '仙台市青葉区',
		area: '青葉町',
	},
	// 03 - Kanto
	関東: {
		postcode: '102-0072',
		prefecture: '東京都',
		city: '千代田区',
		area: '飯田橋',
	},
	// 04 - Chubu
	中部: {
		postcode: '464-0852',
		prefecture: '愛知県',
		city: '名古屋市千種区',
		area: '青柳町',
	},
	// 05 - Hokuriku
	北陸: {
		postcode: '920-0907',
		prefecture: '石川県',
		city: '金沢市',
		area: '青草町',
	},
	// 06 - Kansai
	関西: {
		postcode: '534-0026',
		prefecture: '大阪府',
		city: '大阪市都島区',
		area: '網島町',
	},
	// 07 - Chugoku
	中国: {
		postcode: '730-0851',
		prefecture: '広島県',
		city: '広島市中区',
		area: '榎町',
	},
	// 08 - Shikoku
	四国: {
		postcode: '790-0064',
		prefecture: '愛媛県',
		city: '松山市',
		area: '愛光町',
	},
	// 09 - Kyushu
	九州: {
		postcode: '813-0025',
		prefecture: '福岡県',
		city: '福岡市東区',
		area: '青葉',
	},
};

export const UsageTierData: Record<UsageTier, { title: string }> = {
	LOW: {
		title: '1人暮らし',
	},
	MEDIUM: {
		title: '2〜3人暮らし',
	},
	HIGH: {
		title: '4人暮らし以上 ',
	},
};

const getUsageValuesForArea = (
	selectedArea: SupportedArea | '',
	usageTier: UsageTier | ''
): {
	selectedUsage: number;
	usageHigh: number;
	usageLow: number;
	usageMedium: number;
} => {
	// Return TEPCO values as defaults if selectedArea undefined
	const area: GridOperatorName = selectedArea
		? GridOperatorNameByArea[selectedArea]
		: 'TEPCO';

	const usageLow = getUsageAmount(area, 'LOW');
	const usageMedium = getUsageAmount(area, 'MEDIUM');
	const usageHigh = getUsageAmount(area, 'HIGH');

	return {
		usageLow,
		usageMedium,
		usageHigh,
		selectedUsage:
			usageTier === 'HIGH'
				? usageHigh
				: usageTier === 'MEDIUM'
					? usageMedium
					: usageLow,
	};
};

/**
 * Kansai, Chugoku and Shikoku areas display kVA rather than amperage
 */
const AreasToDisplaykVA: Partial<SupportedArea[]> = ['関西', '中国', '四国'];

export type HomePageSectionQuoteProps = {
	isNewShortcut?: boolean; // Display the new Shortcut design
};

export const HomePageSectionQuote: React.FC<HomePageSectionQuoteProps> = ({
	isNewShortcut = false,
}) => {
	const quoteElementRef = useRef<HTMLDivElement>(null);
	const { filterGreenProduct } = useGreenProduct();
	const [selectedArea, setSelectedArea] = useState<SupportedArea | ''>('');
	const [selectedUsageTier, setSelectedUsageTier] = useState<UsageTier | ''>(
		''
	);
	const [sideDrawerState, setSideDrawerState] = useState<'closed' | 'open'>(
		'closed'
	);
	const [isRedirectingToObj, setIsRedirectingToObj] = useState(false);
	const startOBJWithHomePageQuote = useStartOBJWithHomePageQuote();
	const createQuoteMutation = useMutation({
		mutationKey: ['createAndGetQuoteMutation'],
		mutationFn: createAndGetQuote,
		onError: (error) => {
			Sentry.captureMessage(
				'There was an error in the createAndGetQuote mutation.',
				{ extra: { error } }
			);
		},
	});

	//find cheapest product
	const cheapestProduct = useMemo(
		() =>
			createQuoteMutation.data &&
			getCheapestProduct(
				filterGreenProduct(
					createQuoteMutation.data?.quotedSupplyPoints?.flatMap((sp) =>
						sp?.__typename === 'QuotedElectricitySupplyPoint'
							? (sp.quotedProducts as QuotedElectricityProduct[])
							: []
					) ?? []
				)
			),
		[createQuoteMutation.data]
	);

	const { usageLow, usageMedium, usageHigh, selectedUsage } = useMemo(
		() => getUsageValuesForArea(selectedArea, selectedUsageTier),
		[selectedArea, selectedUsageTier]
	);

	const productParamsQuickQuote = useProductParamsQuickQuote();

	const handleGenerateQuote = async (
		selectedArea: SupportedArea,
		UsageTier: UsageTier
	) => {
		const usageValues = getUsageValuesForArea(selectedArea, UsageTier);
		const postalArea = AreasData[selectedArea];
		if (
			!postalArea?.area ||
			!postalArea?.city ||
			!postalArea?.postcode ||
			!postalArea?.prefecture
		) {
			Sentry.captureMessage(
				'postalArea with missing values used for homepage quick quote',
				{ extra: { AreasData, selectedArea } }
			);
		}
		const shouldUseKva = AreasToDisplaykVA.includes(selectedArea);
		createQuoteMutation.mutate({
			amperage: shouldUseKva ? undefined : DEFAULT_AMPERAGE,
			postalArea,
			usageAmount: usageValues.selectedUsage,
			usageMonth: DefaultUsageMonth,
			productParams: productParamsQuickQuote,
			useCache: false,
		});
		quoteElementRef?.current?.scrollIntoView({ behavior: 'smooth' });
	};

	const handleStartOBJ = (analyticsEvent: string) => {
		setIsRedirectingToObj(true);
		sendHomepagePriceCheckQuoteApplyAnalytics(analyticsEvent);
		const usageValues = getUsageValuesForArea(selectedArea, selectedUsageTier);
		startOBJWithHomePageQuote(
			cheapestProduct as QuotedElectricityProduct,
			createQuoteMutation.data as QuoteWithNonNullableQuotedSupplyPoints,
			usageValues.selectedUsage,
			selectedUsageTier
		);
	};

	const handleSetSelectedArea = (area: SupportedArea) => {
		setSelectedArea(area);
		if (selectedUsageTier) {
			handleGenerateQuote(area, selectedUsageTier);
		}
	};

	const handleSetUsageTier = (numResidents: UsageTier) => {
		setSelectedUsageTier(numResidents);
		if (selectedArea) {
			handleGenerateQuote(selectedArea, numResidents);
		}
	};

	const productName = cheapestProduct?.product.displayName;

	const isSimpleProduct = cheapestProduct?.product?.params?.is_simple;

	const estimatedCosts = cheapestProduct?.estimatedCosts;

	const isLoading = createQuoteMutation.isPending;

	const isReady = estimatedCosts && selectedUsageTier && selectedArea;

	const estimatedPrice = getMetiDiscountPrice(
		estimatedCosts?.quotedMonth.month ?? ''
	)
		? currencyFormatter.format(
				getEstimatedPriceAfterMetiDiscount(
					Number(estimatedCosts?.quotedMonth.cost),
					Number(estimatedCosts?.quotedMonth.usageAmount),
					getMetiDiscountPrice(estimatedCosts?.quotedMonth.month ?? '')
				)
			)
		: currencyFormatter.format(Number(estimatedCosts?.quotedMonth.cost));

	const usageAmperageOrkVACopy =
		selectedArea && AreasToDisplaykVA.includes(selectedArea)
			? '6kVA未満'
			: `${DEFAULT_AMPERAGE.replace(/_/g, '')}A`;

	const subheadingText =
		'料金プランは全てグリーンな電気！'; /* All price plans are green electricity! */
	const headingText =
		'あなたにぴったりの料金プランをチェック'; /* Check the price plan that suits you */

	return (
		<div className="flex flex-col gap-4">
			{isNewShortcut ? (
				<ShortcutForm
					selectedArea={selectedArea || undefined}
					selectedUsageTier={selectedUsageTier || undefined}
					onSelectArea={handleSetSelectedArea}
					onSelectUsageTier={handleSetUsageTier}
				/>
			) : (
				<div className="flex flex-col gap-4 text-white">
					<div className="px-8 sm:px-0">
						<HomePageSectionHeading
							headingText={headingText}
							subheadingText={subheadingText}
						/>
					</div>
					<div className="flex flex-col gap-4 md:flex-row">
						<AreaSelectSection
							setSelectedArea={handleSetSelectedArea}
							selectedArea={selectedArea}
						/>
						<UsageTierSelectSection
							setSelectedUsageTier={handleSetUsageTier}
							selectedUsageTier={selectedUsageTier}
							usageHigh={usageHigh}
							usageLow={usageLow}
							usageMedium={usageMedium}
						/>
					</div>
				</div>
			)}
			<div ref={quoteElementRef} className="px-8 sm:px-0">
				<div
					data-testid="quote-loading"
					className={clsx(
						'block scale-0 space-y-8 transition duration-150 ease-in sm:space-y-16',
						{
							'scale-100': isLoading,
							'invisible h-0': !isLoading,
						}
					)}
				>
					<div className="space-y-8">
						<Skeleton variant="rounded" animation="wave" height={54} />
						<div className="flex justify-center">
							<Skeleton width="45%" variant="rectangular" height={40} />
						</div>
						<div className="h-16 sm:h-32">
							<Skeleton height="100%" variant="rounded" />
						</div>
					</div>
					<div className="space-y-6">
						<Skeleton variant="rectangular" animation="wave" height={16} />
						<Skeleton variant="rectangular" animation="wave" height={16} />
						<Skeleton variant="rectangular" animation="wave" height={16} />
						<Skeleton variant="rectangular" animation="wave" height={16} />
						<Skeleton variant="rectangular" animation="wave" height={16} />
						<Skeleton variant="rectangular" animation="wave" height={16} />
						<Skeleton variant="rectangular" animation="wave" height={16} />
						<Skeleton variant="rectangular" animation="wave" height={16} />
						<Skeleton variant="rectangular" animation="wave" height={16} />
						<Skeleton variant="rectangular" animation="wave" height={16} />
						<Skeleton variant="rectangular" animation="wave" height={16} />
						<Skeleton variant="rectangular" animation="wave" height={16} />
					</div>
					<div className="flex justify-center">
						<Skeleton height={56} width={'50%'} variant="rounded" />
					</div>
				</div>
				{createQuoteMutation.isError && (
					<Alert severity="error">
						{copy.looksLikeSomethingWentWrongTryAgainLater}
					</Alert>
				)}
				{isReady && (
					<Card padding="none">
						<Card.Header
							title={
								<span className="font-bold">{`「${productName}」 の場合`}</span>
							}
						/>
						<Card.Body padding="xsmall">
							<div className="mb-4 grid grid-cols-1 md:flex md:justify-center md:space-x-12">
								<div className="flex items-center justify-between space-x-4 pl-1 md:justify-start md:pr-0">
									<div className="flex h-8 w-8 items-center justify-center">
										<AreaPin />
									</div>
									<span>{selectedArea}</span>
								</div>
								<div className="flex items-center justify-between space-x-4 pl-1 md:justify-start md:pr-0">
									<div className="flex h-8 w-8 items-center justify-center">
										<House />
									</div>
									<div className="text-center">
										{UsageTierData[selectedUsageTier].title}
										<div className="text-xs">(平均{selectedUsage}kWh•月)</div>
									</div>
								</div>
								{!isSimpleProduct && (
									<div className="flex items-center justify-between space-x-4 pl-1 md:justify-start md:pr-0">
										<div className="flex h-8 w-8 items-center justify-center">
											<IconThermostat size={28} duotoneColor="secondary" />
										</div>
										<span>{usageAmperageOrkVACopy}</span>
									</div>
								)}
							</div>
						</Card.Body>
						<Card
							borderless={true}
							theme="midLight"
							padding="small"
							sm={{ padding: 'medium' }}
						>
							<div className="text-center text-base font-bold md:text-3xl">
								{estimatedCosts.quotedMonth.month}月分の月間見積もりは
								<span className="text-2xl md:text-5xl">{estimatedPrice}</span>
								<span className="text-lg">円</span>
							</div>
						</Card>
						<div className="px-4">
							<TariffBarChart estimatedCosts={estimatedCosts} />
						</div>
						<div className="m-auto flex max-w-lg flex-col items-center gap-4">
							<Button
								attributes={{ 'data-testid': 'homepage-estimate-cta' }}
								onClick={() =>
									handleStartOBJ(GA_EVENT_HOMEPAGE_PRICECHECK_QUOTE_APPLY)
								}
								color="primary"
								fullWidth={true}
								loading={isRedirectingToObj}
								loadingLabel={<ConstantineLoader />}
							>
								{copy.applyNow}
							</Button>
							<Button
								color="tertiary"
								variant="ghost"
								fullWidth={true}
								onClick={() => {
									sendHomepagePriceCheckQuoteMoreDetailsAnalytics();
									setSideDrawerState('open');
								}}
							>
								もっと詳しく
							</Button>
						</div>
						<HomePageQuoteSideDrawer
							isGreen={cheapestProduct.product?.params?.is_green}
							onClose={() => setSideDrawerState('closed')}
							state={sideDrawerState}
							estimatedCosts={estimatedCosts}
							tariff={cheapestProduct as QuotedElectricityProduct}
							selectedArea={selectedArea}
							handleStartOBJ={() =>
								handleStartOBJ(GA_EVENT_HOMEPAGE_PRICECHECK_QUOTE_DETAILS_APPLY)
							}
							isRedirectingToObj={isRedirectingToObj}
						/>
					</Card>
				)}
			</div>
		</div>
	);
};
