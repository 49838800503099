import { FC } from 'react';

type HouseProps = {
	className?: string;
};

const House: FC<HouseProps> = ({ className }) => (
	<svg
		width={33}
		height={31}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
		aria-labelledby="single-family-detached-house"
	>
		<title id="single-family-detached-house" lang="ja">
			一戸建ての大きな家 {/* Single family detached house */}
		</title>
		<g clipPath="url(#clip0_898_7778)">
			<path
				d="M5.50863 12.0039H26.3142C27.4138 12.0039 28.3052 12.8555 28.3052 13.906V26.7835C28.3052 27.834 27.4138 28.6856 26.3142 28.6856H5.50863C4.40905 28.6856 3.51766 27.834 3.51766 26.7835V13.906C3.51766 12.8555 4.40905 12.0039 5.50863 12.0039Z"
				fill="#5840FF"
			/>
			<path
				d="M5.11103 5.63281H6.3056C7.13029 5.63281 7.79883 6.27152 7.79883 7.05942V14.6775C7.79883 15.4654 7.13029 16.1041 6.3056 16.1041H5.11103C4.28634 16.1041 3.6178 15.4654 3.6178 14.6775V7.05942C3.6178 6.27152 4.28634 5.63281 5.11103 5.63281Z"
				fill="#5840FF"
			/>
			<path
				d="M2.6218 14.9524H29.3007C29.5184 14.9749 29.1136 14.4878 29.3007 14.3791C29.4879 14.2705 29.6339 14.1774 29.7133 13.9824C29.7745 14.1833 29.7927 13.7874 29.7133 13.9824C29.6521 13.7816 30.174 13.3643 29.9976 13.2405L17.3549 2.8738C16.9716 2.54628 16.4755 2.36523 15.9613 2.36523C15.447 2.36523 14.9509 2.54628 14.5676 2.8738L2.0245 13.2405C1.85735 13.3602 1.78831 13.7919 1.72602 13.9824C1.66372 14.173 2.24301 14.3501 2.30908 14.5395C2.37515 14.7288 2.72264 14.4228 2.89214 14.5395C3.06164 14.6562 2.41286 14.9524 2.6218 14.9524Z"
				fill="#5840FF"
			/>
			<path
				d="M29.2996 14.9524H28.4037L16.657 5.34658C16.4709 5.17232 16.2207 5.07471 15.9602 5.07471C15.6996 5.07471 15.4494 5.17232 15.2633 5.34658L3.51664 14.9524H2.62072C2.41436 14.9262 2.22009 14.8444 2.06067 14.7165C1.90124 14.5886 1.78325 14.42 1.72056 14.2303C1.65788 14.0407 1.65307 13.8379 1.70671 13.6457C1.76035 13.4536 1.87022 13.28 2.02342 13.1454L14.5665 2.8738C14.9498 2.54628 15.446 2.36523 15.9602 2.36523C16.4744 2.36523 16.9706 2.54628 17.3538 2.8738L29.9965 13.1454C30.153 13.286 30.2628 13.4676 30.3121 13.6674C30.3614 13.8672 30.3481 14.0765 30.2738 14.2691C30.1995 14.4618 30.0675 14.6293 29.8943 14.7508C29.7211 14.8723 29.5143 14.9424 29.2996 14.9524V14.9524Z"
				fill="#F0FFFF"
			/>
			<rect
				x="9.94727"
				y="13.8516"
				width="4.65608"
				height="4.44835"
				rx="0.720797"
				fill="#F0FFFF"
			/>
			<rect
				x="9.94727"
				y="20.0781"
				width="4.65608"
				height="4.44835"
				rx="0.720797"
				fill="#F0FFFF"
			/>
			<rect
				x="17.397"
				y="20.0781"
				width="4.65608"
				height="4.44835"
				rx="0.720797"
				fill="#F0FFFF"
			/>
			<rect
				x="17.397"
				y="13.8516"
				width="4.65608"
				height="4.44835"
				rx="0.720797"
				fill="#F0FFFF"
			/>
		</g>
		<defs>
			<clipPath id="clip0_898_7778">
				<rect
					width="29.7143"
					height="26.2857"
					fill="white"
					transform="matrix(-1 0 0 1 30.8574 2.28516)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export { House };
