import * as Sentry from '@sentry/nextjs';

import { isBrowser } from '@/utils/browser/isBrowser';

type Value<T> = T | null;

export function readLocalStorageOnce<T>(key: string): Value<T> {
	if (!isBrowser()) {
		return null;
	}

	try {
		const item = window.localStorage.getItem(key);
		return item ? (JSON.parse(item) as T) : null;
	} catch (error) {
		Sentry.captureMessage(`Error reading localStorage key "${key}":`, {
			extra: { error },
		});
		return null;
	}
}
